/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { compose } from "recompose";
import { withFirebase } from "layouts/Firebase";
import { withRouter } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { Link } from "react-router-dom";
import { Modal } from "@material-ui/core";
import AddNewEquipment from "./AddNewEquipment";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { store } from "react-context-hook";
//columns for muidatatables
const columns = [
  {
    name: "Ser_No",
    label: "Serial No.",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Customer Name",
    label: "Customer Name",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "Phone No",
    label: "Phone No",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Machine_Ser_NO",
    label: "Serial No.",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "Contract Type",
    label: "Contract Type",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (
          value === "AMC" ||
          value === "CMC" ||
          value === "cmc" ||
          value === "warranty" ||
          value === "WARRANTY" ||
          value === " WARRANTY" ||
          value === "WARRANTY "
        )
          return (
            <td style={{ backgroundColor: "#23CE6B", color: "#fff" }}>
              {value}
            </td>
          );
        else
          return (
            <td style={{ backgroundColor: "#DB162F", color: "#fff" }}>
              {value}
            </td>
          );
      },
    },
  },

  {
    name: "Customer/ Clinic Name",
    label: "Customer/ Clinic Name",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "Equipment",
    label: "Equipment",
    options: {
      filter: true,
      sort: false,
      display: false,
    },
  },

  {
    name: "Address",
    label: "Address",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },

  {
    name: "PROBE_01",
    label: "PROBE_01",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "PROBE_02",
    label: "PROBE_02",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "PROBE_03",
    label: "PROBE_03",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "installation date",
    label: "installation date",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "StartDate",
    label: "StartDate",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "EndDate",
    label: "EndDate",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "START-END (MINDRAY)",
    label: "START-END (MINDRAY)",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "Contract Amount",
    label: "Contract Amount",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "Invoice number",
    label: "Invoice number",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "Invoice number",
    label: "Invoice number",
    options: {
      filter: false,
      sort: true,
      display: false,
    },
  },
  {
    name: "Model",
    label: "Model",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "District",
    label: "District",
    options: {
      filter: true,
      sort: false,
      //display: false
    },
  },
  {
    name: "ID",
    label: "Details",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Link to={`/admin/mindray/${value}`}>
            <Button color="info">Details</Button>
          </Link>
        );
      },
    },
  },
];

const options = {
  filterType: "checkbox",
  download: false,
  rowsPerPage: 100,
  responsive: "none",
  selectableRows: "none",
  print: true,
  // downloadOptions: { filename: "Mindray.csv", separator: "," },
};

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  paper: {
    maxWidth: "70%",
    overflowY: "auto",
    margin: "auto",
  },
};
class TableList extends Component {
  constructor(props) {
    super(props);

    this.state = { CustomerList: [], FilterdCustomer: [], setting: {} };
  }
  componentDidMount() {
    // reading seting for counting and actual values value from realtime database
    this.props.firebase.setting().on("value", (snapshot) => {
      const setting = snapshot.val();
      this.setState({ setting });
    });

    //getting initial machine list from collection
    this.props.firebase.getMindrayIB().onSnapshot((snapshot) => {
      let CustomerList = [];
      snapshot.forEach((doc) => {
        CustomerList.push({
          ...doc.data(),
          ID: doc.id,
        });
      });
      CustomerList.sort((a, b) => {
        return a.Ser_No - b.Ser_No;
      });
      this.setState({
        CustomerList,
        FilterdCustomer: CustomerList,
        loading: false,
      });
      // filter and updating outdated machines
      CustomerList.map((doc) => {
        const time1 = moment(doc.EndDate).format("YYYY-MM-DD");
        const time2 = moment().format("YYYY-MM-DD");

        if (time2 > time1 && doc["Contract Type"] !== "PERCALL") {
          this.props.firebase
            .getSingleMindrayIB(doc.ID)
            .update({
              "Contract Type": "PERCALL",
            })
            .then(() => console.log(doc.ID));
        } else if (time2 < time1) {
          console.log(time2);
          console.log("date 2 is Less than date 1");
          console.log(time1);
        } else {
          console.log("Both date are same");
        }
      });
    });
  }

  //for creating bulk pm Calls
  CreatePM = () => {
    let currentList = [];
    const { setting } = this.state;
    currentList = this.state.CustomerList;
    const brokenDate = [];
    let Current_Call_ID = setting.Current_Call_ID;

    currentList.map((item) => {
      if (
        item["Contract Type"] === "AMC" ||
        item["Contract Type"] === "CMC" ||
        item["Contract Type"] === "cmc" ||
        item["Contract Type"] === "warranty" ||
        item["Contract Type"] === "WARRANTY"
      ) {
        const time1 = moment(item.StartDate).format("YYYY-MM-DD");
        const time2 = moment(item.EndDate).format("YYYY-MM-DD");
        const t1 = moment([time1]);
        const t2 = moment([time2]);
        const monthDifference = t2.diff(t1, "months", true);
        if (isNaN(monthDifference) || monthDifference === "") {
          const date = 0;
        } else {
          if (monthDifference !== 0) {
            const j = monthDifference / 4;

            console.log(item.Machine_Ser_NO);
            console.log(monthDifference, time1);
            for (let i = 1; i <= j; i++) {
              const date = moment("2019-10-10");
              const newdate = moment(time1)
                .add(i * 4, "months")
                .format("YYYY-MM-DD");
              const datedata = newdate.split("-");

              const CallID = "ASRB" + Current_Call_ID;
              this.props.firebase
                .SingleCall(CallID)
                .set({
                  Date: datedata[2],
                  Month: datedata[1],
                  Year: datedata[0],
                  Company: "Mindray",
                  Call_Type: "PM",
                  Details: "",
                  Engineer_Name: "",
                  CallStatus: "Scheduled",
                  Ser_No: item["Machine_Ser_NO"],
                  CustomerName: item["Customer Name"],
                  Contract_Type: item["Contract Type"],
                })
                .then(() => {
                  console.log("done");
                });
              Current_Call_ID = Number(Current_Call_ID) + 1;
            }
          }
        }
      }
    });

    this.props.firebase.setting().update({
      Current_Call_ID,
    });
    this.setState({ brokenDate });
  };

  //for closing "Add New equipment " Modal and giving alert
  handleToggle = () => {
    this.setState({ modalOpen: false });
    alert("Added Successfully");
  };
  handleExpired = (data) => {
    data.map((item) => {
      const date = moment().format("DD-MMM-YY");
      console.log(date);
    });
  };
  handleUpdate = () => {
    // Variable to hold the original version of the list
    let currentList = [];

    currentList = this.state.CustomerList;
    currentList.map((item) => {
      const EndDate = moment(item.EndDate).format("YYYY-MM-DD");
      const StartDate = moment(item.EndDate).format("YYYY-MM-DD");
      const installDate = moment(item["installation date"]).format(
        "YYYY-MM-DD"
      );
      this.props.firebase.getSingleIB(item.ID).update({
        EndDate,
        StartDate,
        "installation date": installDate,
      });
      console.log(`done + ${item.ID} +E${EndDate}`);
    });
  };

  // custome style for mui datatable
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableCell: {
          root: {
            padding: "none",
            paddingLeft: "10px",
          },
        },
      },
    });
  render() {
    const { classes } = this.props;
    const { FilterdCustomer, setting } = this.state;
    return (
      <GridContainer>
        <Modal
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          className={classes.paper}
        >
          <AddNewEquipment
            ID={setting.Current_Mindray_ID}
            handleToggle={this.handleToggle}
          />
        </Modal>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Customer List</h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <Button
                color="success"
                onClick={() => this.setState({ modalOpen: true })}
              >
                Add New Equipment
              </Button>

              <MuiThemeProvider theme={this.getMuiTheme()}>
                <MUIDataTable
                  data={FilterdCustomer}
                  columns={columns}
                  options={options}
                  onRowsSelect={(currentRowsSelected) => alert("hellow")}
                />
              </MuiThemeProvider>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

TableList.propTypes = {
  classes: PropTypes.object,
};

export default compose(
  withFirebase,
  withRouter,
  withStyles(styles)
)(TableList);
