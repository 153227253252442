import React, { Component } from "react";
import { withFirebase } from "layouts/Firebase";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { compose } from "recompose";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-react.jsx";
import { Modal } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import PartList from "./Component/PartList";
import ItemList2 from "./Component/ItemList";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + " !important",
      borderWidth: "1px !important",
    },
    "&:after": {
      borderColor: primaryColor[0],
    },
  },

  paper: {
    maxWidth: "70%",
    overflowY: "auto",
    margin: "auto",
  },
};

const INITIAL_STATE = {
  ItemType: "",
  Customer_Details: "",
  Customer_Contact: "",
  Price: "",
  Expected_Return: "",
  Manual_Recipt: "",
  setting: "",
  Date: "",
  Month: "",
  Year: "",
  Company: "",
  Call_Type: "",
  Details: "",
  Engineer_Name: "",
  Charge: "",
  Quantity: "",
  TicketType: "Sell",
  Parts: [],
  SellItems: [],
  TicketStatus: "",
  CRM_No: "",
  SellItems: [],
  SellItemsList: [],
};
const testdata = {
  ItemType: "test",
  TicketType: "test",
  Customer_Details: "test",
  Customer_Contact: "test",
  Engineer_Name: "test",
  Authorise_Person: "test",
  Price: "test",
  Expected_Return: "test",
  Manual_Recipt: "test",
  Quantity: "test",
  CRM_No: "test",
};

class CreateBill extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE, SellItemsList: [] };
  }
  componentDidMount() {
    if (this.props.edit) {
      //alert("Edit Option");
      const item = this.props.Ticket;
      this.setState({
        ...item,
      });
    }
    //Reading Part Names from the collection
    this.props.firebase.getPartNames().onSnapshot((snapshot) => {
      let PartNames = [];

      snapshot.forEach((doc) => {
        PartNames.push({
          ...doc.data(),
        });
      });
      this.setState({ PartNames });
    });
    this.props.firebase.getInventoryItems().onSnapshot((snapshot) => {
      let ItemList = [];
      snapshot.forEach((doc) => {
        ItemList.push({
          ...doc.data(),
          Sr: doc.ID,
        });
      });
      const FiteredItems = ItemList.filter(
        (item) => item.StockStatus === "InStock"
      );
      this.setState({ ItemList: FiteredItems });
    });
    //getting initial machine list from collection
    this.props.firebase.getTickets().onSnapshot((snapshot) => {
      let Tickets = [];

      snapshot.forEach((doc) => {
        Tickets.push({
          ...doc.data(),
          ID: doc.id,
        });
      });
      this.setState({
        Tickets,
      });
    });
    // reading seting for counting and actual values value from realtime database
    this.props.firebase.setting().on("value", (snapshot) => {
      const setting = snapshot.val();
      this.setState({ setting });
    });
  }
  addnewline = () => {
    let Parts = [];
    const { PartName, Type, SerNo, Outdate, Status } = this.state;
    if (this.state.Parts !== null) {
      Parts = this.state.Parts;
    }
    if (
      (PartName.length,
      Type.length,
      SerNo.length,
      Outdate.length,
      Status.length)
    ) {
      Parts.push({ PartName, Type, SerNo, Outdate, Status });
      this.setState({
        Parts,
        PartName: "",
        Type: "",
        SerNo: "",
        Outdate: "",
        Status: "",
      });
    } else {
      alert("Please Fill All Fields");
    }
  };
  deleteLine = (key) => {
    let Parts = [];
    if (this.state.Parts !== null) {
      Parts = this.state.Parts;
    }
    let index = -1;
    Parts.find((item, i) => {
      if (item.PartName === key) {
        index = i;
        return;
      }
    });

    Parts.splice(index, 1);
    this.setState({ Parts });
  };
  SendMail = (
    Ticket_ID,
    TicketStatus,
    Date,
    ItemType,
    TicketType,
    Customer_Details,
    Customer_Contact,
    Engineer_Name,
    Authorise_Person,
    Price,
    Expected_Return,
    Manual_Recipt,
    Quantity,
    CRM_No,
    Parts,
    SellItemsList
  ) => {
    let Table =
      "<table><tr><th>ID</th><th>SerialNo</th><th>ModalName</th><th>SellQuantity</th></tr>";
    const test = SellItemsList?.map((item) => {
      Table += `<tr><th>${item.ID}</th><th>${item.SerialNo}</th> <th>${item.ModalName}</th><th>${item.SellQuantity}</th></tr>`;
    });
    Table += "</table>";
    var template_params = {
      from: "crm@asrbmedicalservices.com",
      to:
        "asrbcrm@gmail.com,srivastav.bk1957@gmail.com,asrbmedicalservices@gmail.com,asrbcoordinator@gmail.com,rahul121azad@gmail.com",
      subject: `Ticket Creation Alert ID : ${Ticket_ID}`,
      html: `New Ticket has been created here are the details
       <b> TicketID :-</b> ${Ticket_ID},<br/>
       <b> Status:-</b>  ${TicketStatus},<br/>
       <b> Date:-</b>  ${Date},<br/>
       <b> ItemType:-</b> ${ItemType},<br/>
       <b> TicketType:-</b> ${TicketType},<br/>
       <b> Customer Details:-</b> ${Customer_Details},<br/>
       <b> Customer Contact:-</b> ${Customer_Contact},<br/>
       <b> Engineer Name:-</b> ${Engineer_Name},<br/>
       <b> Authorise Person:-</b> ${Authorise_Person},<br/>
       <b> Price:-</b> ${Price},<br/>
       <b> Expected Return:-</b> ${Expected_Return},<br/>
       <b> Manual Recipt:-</b> ${Manual_Recipt},<br/>
       <b> Quantity :-</b> ${Quantity},<br/>
       <b> CRM No:-</b> ${CRM_No},<br/>
       <b> Parts:-</b> ${JSON.stringify(Parts)},<br/>
       <b> SellItemsList:-</b> ${Table}, <br/>`,
      text: `New Ticket has been created here are the details 
        TicketID :- ${Ticket_ID},
        Status:- ${TicketStatus},
        Date:- ${Date},
        ItemType:- ${ItemType},
        TicketType:- ${TicketType},
        Customer Details:- ${Customer_Details},
        Customer Contact:- ${Customer_Contact},
        Engineer Name:- ${Engineer_Name},
        Authorise Person:- ${Authorise_Person},
        Price:- ${Price},
        Expected Return:- ${Expected_Return},
        Manual Recipt:- ${Manual_Recipt},
        Quantity :- ${Quantity},
        CRM No:- ${CRM_No},
        Parts:- ${JSON.stringify(Parts)},
        SellItemsList:- ${Table},`,
    };

    fetch("https://www.ranjansingh.dev/api/email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(template_params),
    })
      .then(async (response) => {
        console.log(await response.json());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  deleteSellItem = (id) => {
    let SellItems = [];
    if (this.state.SellItems) SellItems = this.state.SellItems;
    let index2 = -1;
    SellItems.find((item, i) => {
      if (item.ID === id) {
        index2 = i;
        return;
      }
    });
    if (index2 !== -1) SellItems.splice(index2, 1);
    let SellItemsList = [];
    if (this.state.SellItemsList) SellItemsList = this.state.SellItemsList;
    let index = -1;
    SellItemsList.find((item, i) => {
      if (item.ID === id) {
        index = i;
        return;
      }
    });
    if (index !== -1) SellItemsList.splice(index, 1);
    this.setState({ SellItemsList, SellItems });
  };

  AddSellItem = (SelectedIB) => {
    const { Quantity, SellItems, SellItemsList, TicketType } = this.state;
    if (Number(Quantity) > Number(SelectedIB.Quantity)) {
      alert(" Quantity Not Available");
      return;
    }
    SellItems.push({
      ...SelectedIB,
      SellQuantity: Quantity,
    });
    SellItemsList.push({
      ID: SelectedIB.ID,
      SerialNo: SelectedIB.SerialNo,
      ModalName: SelectedIB.ModalName,
      Type: SelectedIB.Type,
      SellQuantity: Quantity,
    });
    let NewSelectedID = [];
    if (TicketType === "Part Out") NewSelectedID = SelectedIB;
    this.setState({
      ItemType: "",
      SellItems,
      SellItemsList,
      Quantity: "",
      SelectedIB: NewSelectedID,
    });
  };
  submit_handler = () => {
    const {
      TicketType,
      Customer_Details,
      SelectedIB,
      setting,
      Date,
      SellItems,
      Manual_Recipt,
      CRM_No,
      Tickets,
    } = this.state;
    if (Manual_Recipt.length && CRM_No.length) {
      let duplicate = false;
      Tickets.map((item) => {
        if (item.CRM_No === CRM_No || item.Manual_Recipt === Manual_Recipt)
          duplicate = true;
        return;
      });
      if (duplicate) {
        alert(
          "CRM No. OR  Manual_Recipt Already exists please Try again with new one"
        );
        return;
      }
      const Ticket_ID = "Ticket00" + setting.Current_Ticket_ID;

      if (TicketType === "Part Out") {
        let History = [];
        let Parts = [];
        let Status = "";
        if (SelectedIB.History) History = SelectedIB.History;
        if (SelectedIB.Parts) Parts = SelectedIB.Parts;
        History.push({
          Date,
          Ticket_ID,
          Details: `Part sent to ${Customer_Details}`,
        });
        //adding misiing parst to the list
        Parts = Parts.concat(this.state.Parts);
        this.setState({ Status: "Open" });
        this.props.firebase
          .getSingleInventoryItem(SelectedIB.ID)
          .update({
            Status: "Inactive",
            History,
            Parts,
          })
          .then(() => this.addTicket());
      } else if (TicketType === "Sell") {
        SellItems.map((Item) => {
          let History = [];
          let StockStatus = "Sold";
          let newQuantity = 0;
          let Details = `sold  to ${Customer_Details}`;
          if (Item.History && Item.History !== undefined)
            History = Item.History;
          if (Item.Type === "Accesories") {
            newQuantity = Item.Quantity - Item.SellQuantity;
            newQuantity === 0
              ? (StockStatus = "Sold")
              : (StockStatus = "InStock");
            Details = `sold , ${Item.SellQuantity} to ${Customer_Details}`;
          }
          History.push({
            Date,
            Ticket_ID,
            Details,
          });
          if (Item.Type === "Product") StockStatus = "Sold";
          this.setState({ TicketStatus: "Closed" });
          this.props.firebase.getSingleInventoryItem(Item.ID).update({
            Quantity: newQuantity,
            StockStatus,
            History,
          });
        });
        this.addTicket();
      }
    } else {
      alert("Please provide CRM No & Manual_Recipt");
      return;
    }
  };

  updateTicket = () => {
    const {
      ItemType,
      TicketType,
      Customer_Details,
      Customer_Contact,
      Engineer_Name,
      Authorise_Person,
      Price,
      Expected_Return,
      Manual_Recipt,
      setting,
      Date,
      Quantity,
      CRM_No,
      TicketStatus,
      SellItems,
      SellItemsList,
    } = this.state;
    this.props.firebase.getSingleTicket(this.props.id).update({
      ItemType,
      TicketType,
      Customer_Details,
      Customer_Contact,
      Engineer_Name,
      Authorise_Person,
      Price,
      Expected_Return,
      Manual_Recipt,
      setting,
      Date,
      Quantity,
      CRM_No,
      TicketStatus,
      SellItems,
      SellItemsList,
    });
    alert("Updated Successfully");
  };
  addTicket = () => {
    const {
      ItemType,
      TicketType,
      Customer_Details,
      Customer_Contact,
      Engineer_Name,
      Authorise_Person,
      Price,
      Expected_Return,
      Manual_Recipt,
      setting,
      Date,
      Quantity,
      CRM_No,
      TicketStatus,
      SellItems,
      SellItemsList,
    } = this.state;

    const Ticket_ID = "Ticket00" + setting.Current_Ticket_ID;

    this.props.firebase
      .getSingleTicket(Ticket_ID)
      .set({
        Status: TicketStatus,
        Date: this.state.Date,
        ItemType,
        TicketType,
        Customer_Details,
        Customer_Contact,
        Engineer_Name,
        Authorise_Person,
        Price,
        Expected_Return,
        Manual_Recipt,
        Quantity,
        CRM_No,
        Parts: this.state.Parts,
        SellItemsList,
      })
      .then(() => {
        this.SendMail(
          Ticket_ID,
          TicketStatus,
          this.state.Date,
          ItemType,
          TicketType,
          Customer_Details,
          Customer_Contact,
          Engineer_Name,
          Authorise_Person,
          Price,
          Expected_Return,
          Manual_Recipt,
          Quantity,
          CRM_No,
          this.state.Parts,
          SellItemsList
        );
        this.setState({
          ItemType: "",
          TicketType: "",
          Customer_Details: "",
          Customer_Contact: "",
          Engineer_Name: "",
          Authorise_Person: "",
          Price: "",
          Expected_Return: "",
          Manual_Recipt: "",
          Quantity: "",
          CRM_No: "",
          TicketStatus: "",
          SellItems: [],
        });

        alert("Added Successfully");
        const newID = Number(setting.Current_Ticket_ID) + 1;
        this.props.firebase.setting().update({
          Current_Ticket_ID: newID,
        });
      });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const columns = [
      {
        name: "ID",
        label: "S/N",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "ModalName",
        label: "Modal Name",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Manufacturer",
        label: "Manufacturer",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "SerialNo",
        label: "Serial No.",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Price",
        label: "Price",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "StockStatus",
        label: "Stock Status",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === "InStock")
              return (
                <td style={{ backgroundColor: "#23CE6B", color: "#fff" }}>
                  {value}
                </td>
              );
            else
              return (
                <td style={{ backgroundColor: "#DB162F", color: "#fff" }}>
                  {value}
                </td>
              );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      download: false,
      rowsPerPage: 15,
      selectableRows: "none",
      download: false,
      onRowClick: (rowData, rowState) => {
        let { SelectedIB } = this.state;
        this.state.ItemList.map((data) => {
          if (data.ID === rowData[0]) SelectedIB = data;
        });
        this.setState({ SelectedIB, modalOpen: false });
        this.AddSellItem(SelectedIB);
      },
      print: false,
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <div></div>
      ),
    };

    const { classes } = this.props;
    let {
      Company,
      MindrayIB,
      FujiIB,
      ItemList,
      OpenTickets,
      ClosedTickets,
    } = this.state;
    return (
      <>
        <Card>
          <Modal
            open={this.state.modalOpen}
            onClose={() => this.setState({ modalOpen: false })}
            className={classes.paper}
          >
            <div className={classes.Table}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Select the Machine</h4>
                </CardHeader>
                <CardBody>
                  <MUIDataTable
                    data={ItemList}
                    columns={columns}
                    options={options}
                  />
                </CardBody>
              </Card>
            </div>
          </Modal>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Create Ticket</h4>
          </CardHeader>
          <CardBody>
            <Button onClick={() => this.SendMail(testdata.ItemType)}>
              Send TESt MAil
            </Button>
            <GridContainer>
              <GridItem xs={4} sm={4} md={2}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Select Ticket Type</InputLabel>
                  <Select
                    name="TicketType"
                    value={this.state.TicketType}
                    input={
                      <Input
                        classes={{ underline: classes.underline }}
                        name="TicketType"
                        onChange={this.onChange}
                      />
                    }
                  >
                    <MenuItem value="Sell">Product Sell Ticket</MenuItem>
                    <MenuItem value="Part Out">Part Out</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Opening Date"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "Date",
                    type: "date",
                    value: this.state.Date,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Customer Details"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "Customer_Details",
                    type: "text",
                    value: this.state.Customer_Details,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Customer Contact"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "Customer_Contact",
                    type: "text",
                    value: this.state.Customer_Contact,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Engineer Name"
                  id="email-address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "Engineer_Name",
                    type: "text",
                    value: this.state.Engineer_Name,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Price"
                  id="email-address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "Price",
                    type: "text",
                    value: this.state.Price,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Authorise Person"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "Authorise_Person",
                    type: "text",
                    value: this.state.Authorise_Person,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Expected Return"
                  id="email-address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "Expected_Return",
                    type: "text",
                    value: this.state.Expected_Return,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="CRM No. Info"
                  id="email-address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "CRM_No",
                    type: "text",
                    value: this.state.CRM_No,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Manual Recipt No"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "Manual_Recipt",
                    type: "text",
                    value: this.state.Manual_Recipt,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
            </GridContainer>
            {!this.props.edit ? (
              <GridContainer>
                <ItemList2
                  edit={true}
                  deleteLine={this.deleteSellItem}
                  Items={this.state.SellItems}
                />
                <GridItem xs={4} sm={4} md={2}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>Select Item Type</InputLabel>
                    <Select
                      name="ItemType"
                      value={this.state.ItemType}
                      input={
                        <Input
                          classes={{ underline: classes.underline }}
                          name="ItemType"
                          onChange={this.onChange}
                        />
                      }
                    >
                      <MenuItem value="Product">Product</MenuItem>
                      <MenuItem value="Accesories">Accesories</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                {this.state.ItemType === "Accesories" && (
                  <GridItem xs={12} sm={12} md={2}>
                    <CustomInput
                      labelText="Quantity"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "Quantity",
                        type: "text",
                        value: this.state.Quantity,
                        onChange: this.onChange,
                      }}
                    />
                  </GridItem>
                )}

                <GridItem xs={12} sm={12} md={3}>
                  <Button
                    color="info"
                    onClick={() => this.setState({ modalOpen: true })}
                  >
                    Select Machine / Accesories
                  </Button>
                </GridItem>
              </GridContainer>
            ) : null}
            {this.state.TicketType === "Part Out" &&
              this.state.ItemType !== "Accesories" && (
                <>
                  <GridContainer
                    style={{ boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)" }}
                  >
                    <PartList Items={this.state.Parts} />
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={10} sm={10} md={2}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Select Part</InputLabel>
                        <Select
                          name="PartName"
                          value={this.state.PartName}
                          input={
                            <Input
                              classes={{ underline: classes.underline }}
                              name="PartName"
                              onChange={this.onChange}
                            />
                          }
                        >
                          {this.state.PartNames &&
                            this.state.PartNames.map((item) => (
                              <MenuItem value={item.Name}>{item.Name}</MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={2} sm={2} md={1}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Tooltip
                          id="tooltip-top"
                          title="Add New Part"
                          placement="top"
                          classes={{ tooltip: classes.tooltip }}
                        >
                          <Link to="/admin/inventory/addpart">
                            <Button color="info">+</Button>
                          </Link>
                        </Tooltip>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Select Type</InputLabel>
                        <Select
                          name="Type"
                          value={this.state.Type}
                          input={
                            <Input
                              classes={{ underline: classes.underline }}
                              name="Type"
                              onChange={this.onChange}
                            />
                          }
                        >
                          <MenuItem value="Internal">Internal</MenuItem>
                          <MenuItem value="External">External</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="SerNo"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "SerNo",
                          type: "text",
                          onChange: this.onChange,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Outdate"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "Outdate",
                          type: "text",
                          onChange: this.onChange,
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Select Status</InputLabel>
                        <Select
                          name="Status"
                          value={this.state.Status}
                          input={
                            <Input
                              classes={{ underline: classes.underline }}
                              name="Status"
                              onChange={this.onChange}
                            />
                          }
                        >
                          <MenuItem value="Available">Available</MenuItem>
                          <MenuItem value="Not Available">
                            Not Available
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer justify="flex-start">
                    <GridItem xs={12} sm={12} md={3}>
                      <Button onClick={this.addnewline} color="success">
                        ADD Item
                      </Button>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}></GridItem>
                  </GridContainer>
                </>
              )}
            {this.props.edit ? (
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={3}>
                  <Button onClick={this.updateTicket} color="info">
                    Update
                  </Button>
                </GridItem>
              </GridContainer>
            ) : (
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={3}>
                  <Button onClick={this.submit_handler} color="info">
                    Submit
                  </Button>
                </GridItem>
              </GridContainer>
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(style)
)(CreateBill);
