import React, { useEffect, useState } from "react";
import CreateTicket from "./CreateTicket";
import MUIDataTable from "mui-datatables";
import { withFirebase } from "layouts/Firebase";
import { Link } from "react-router-dom";

const ListTickets = (props) => {
  const [OpenTickets, setOpenTickets] = useState([]);
  const [ClosedTickets, setClosedTickets] = useState([]);
  const columns2 = [
    {
      name: "ID",
      label: "ID",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Link to={`/admin/Tickets/${value}`}>{value}</Link>;
        },
      },
    },
    {
      name: "TicketType",
      label: "Ticket Type",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Date",
      label: "Date ",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Customer_Contact",
      label: "Customer_Contact",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Customer_Details",
      label: "Customer_Details",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "Engineer_Name",
      label: "Engineer Name",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "CRM_No",
      label: "CRM_No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Manual_Recipt",
      label: "Manual Reciept",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "Authorise_Person",
      label: "Authorise_Person",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "Expected_Return",
      label: "Expected_Return",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "Price",
      label: "Price",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];
  const options2 = {
    filterType: "checkbox",
    download: false,
    rowsPerPage: 15,
    selectableRows: "none",
    download: false,
    print: false,
  };
  useEffect(() => {
    props.firebase.getTickets().onSnapshot((snapshot) => {
      let Tickets = [];

      snapshot.forEach((doc) => {
        Tickets.push({
          ...doc.data(),
          ID: doc.id,
        });
      });

      let OpenTickets = [],
        ClosedTickets = [];
      Tickets.map((item) => {
        if (item.Status === "Open")
          OpenTickets.push({
            ...item,
          });
        else
          ClosedTickets.push({
            ...item,
          });
      });
      setClosedTickets(ClosedTickets);
      setOpenTickets(OpenTickets);
      //this.setState({ OpenTickets, ClosedTickets });
    });
  }, []);
  return (
    <div>
      <CreateTicket />
      <MUIDataTable data={OpenTickets} columns={columns2} options={options2} />
      <MUIDataTable
        data={ClosedTickets}
        columns={columns2}
        options={options2}
      />
    </div>
  );
};

export default withFirebase(ListTickets);
