import React, { Component } from "react";
import { compose } from "recompose";
import { withFirebase } from "layouts/Firebase";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import AddNewItem from "./AddNewItem";
import Button from "components/CustomButtons/Button.jsx";

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Modal,
} from "@material-ui/core";

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  paper: {
    maxWidth: "70%",
    overflowY: "auto",
    margin: "auto",
  },
};
export class SingleItem extends Component {
  constructor(props) {
    super(props);
    this.state = { Item: [] };
  }
  componentDidMount() {
    this.props.firebase
      .getSingleInventoryItem(this.props.match.params.id)
      .get()
      .then((doc) => this.setState({ Item: doc.data() }));
  }
  DeleteInventoryItem = () => {
    this.props.firebase
      .getSingleInventoryItem(this.props.match.params.id)
      .delete()
      .then(() => {
        alert("Deleted Successfull !");
        this.props.history.push("/admin/Inventory");
      });
  };
  render() {
    const { classes } = this.props;
    const { Item } = this.state;
    return (
      <>
        <Modal
          open={this.state.modalDeleteOpen}
          onClose={() => this.setState({ modalDeleteOpen: false })}
          className={classes.paper}
        >
          <Card>
            <CardHeader color="danger">
              Do you want to Delete This Inventory Item ?
            </CardHeader>
            <CardBody>
              <Button color="danger" onClick={this.DeleteInventoryItem}>
                Yes Delete Now
              </Button>
              <Button onClick={() => this.setState({ modalDeleteOpen: false })}>
                cencel
              </Button>
            </CardBody>
          </Card>
        </Modal>
        <Modal
          open={this.state.modalUpdate}
          onClose={() => this.setState({ modalUpdate: false })}
          className={classes.paper}
        >
          <AddNewItem Item={Item} Update={true} />
        </Modal>
        <Modal
          open={this.state.modalUpdate}
          onClose={() => this.setState({ modalUpdate: false })}
          className={classes.paper}
        >
          <AddNewItem Item={Item} Update={true} />
        </Modal>
        <Card>
          <CardHeader color="primary">
            {" "}
            <h4 className={classes.cardTitleWhite}>Item ID : {Item.ID}</h4>
          </CardHeader>
          <CardBody color="primary">
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    Stock Status :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.StockStatus}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    Manufacturer :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.Manufacturer}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    MFD :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.MFD}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    Modal Name :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.ModalName}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    Price :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.Price}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    Quantity :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.Quantity}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    SerialNo :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.SerialNo}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    Shipping Date :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.BillingDate}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    Status :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.Status}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    Location :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.Location}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    Billing Date :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.BillingDate}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    Type :
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {Item.Type}{" "}
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    <Button
                      onClick={() => this.setState({ modalUpdate: true })}
                      color="info"
                    >
                      Edit
                    </Button>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Button
                      onClick={() => this.setState({ modalDeleteOpen: true })}
                      color="danger"
                    >
                      Delete
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem
                style={{ boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)" }}
                xs={12}
                sm={6}
                md={6}
              >
                Missing Part
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Outdate</TableCell>
                      <TableCell>Part Name</TableCell>
                      <TableCell>Ser No</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Type</TableCell>
                    </TableRow>
                  </TableHead>
                  {Item.Parts &&
                    Item.Parts.map((part) => (
                      <TableBody>
                        <TableCell>{part.Outdate}</TableCell>
                        <TableCell>{part.PartName}</TableCell>
                        <TableCell>{part.SerNo}</TableCell>
                        <TableCell>{part.Status}</TableCell>
                        <TableCell>{part.Type}</TableCell>
                      </TableBody>
                    ))}
                </Table>
              </GridItem>
              <GridItem
                style={{ boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)" }}
                xs={6}
                sm={6}
                md={6}
              >
                History
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Ticket ID</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  {Item.History &&
                    Item.History.map((part) => (
                      <TableBody>
                        <TableCell>{part.Date}</TableCell>
                        <TableCell>{part.Ticket_ID}</TableCell>
                        <TableCell>{part.Details}</TableCell>
                      </TableBody>
                    ))}
                </Table>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(style)
)(SingleItem);
