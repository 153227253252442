import React, { memo } from "react";

import { useStoreState, useSetStoreValue } from "react-context-hook";

export function withGlobalStore(BaseComponent) {
  const Component = props => {
    const store = useStoreState();

    return <BaseComponent {...props} store={store} />;
  };

  return memo(Component);
}
export function SetStore(props) {
  const Itemvalue = useSetStoreValue(props.key);
  Itemvalue(props.value);
  return null;
}
