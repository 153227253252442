/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { Component } from "react";
import { withFirebase } from "layouts/Firebase";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { compose } from "recompose";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Modal,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import PartList from "./Component/PartList";
import Tooltip from "@material-ui/core/Tooltip";

import {
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-react.jsx";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + " !important",
      borderWidth: "1px !important",
    },
    "&:after": {
      borderColor: primaryColor[0],
    },
  },
};

const INITIAL_STATE = {
  ID: "",
  SerialNo: "",
  Manufacturer: "",
  MFD: "",
  BillingDate: "",
  Status: "",
  Parts: [],
  Type: "",
  Price: "",
  Quantity: 1,
  History: [],
  ModalName: "",
  StockStatus: "",
  Location: "",
  Update: false,
  SerialNoList: [],
  AddedItemList: 0,
};
class AddNewItem extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, ModalNames: [], ModalOption: [] };
  }
  componentDidMount() {
    // reading seting for counting and actual values value from realtime database
    this.props.firebase.setting().on("value", (snapshot) => {
      const setting = snapshot.val();
      this.setState({ setting });
    });
    this.props.firebase.getModalNames().onSnapshot((snapshot) => {
      let ModalNames = [];
      snapshot.forEach((doc) => {
        ModalNames.push({
          ...doc.data(),
        });
      });
      const ModalOption = [];
      ModalNames.map((item) =>
        ModalOption.push({ Name: item.Name, value: item.Name })
      );
      this.setState({ ModalNames, ModalOption });
    });
    //checking for form status if is is update this wiil change the state Update : true
    if (this.props.Update)
      this.setState({ Update: this.props.Update, ...this.props.Item });

    this.props.firebase.getInventoryItems().onSnapshot((snapshot) => {
      let ItemList = [];
      snapshot.forEach((doc) => {
        ItemList.push({
          ...doc.data(),
          Sr: doc.ID,
        });
      });
      this.setState({ ItemList });
    });
  }
  addNewserial = () => {
    const { SerialNoList, SerialNo, Quantity } = this.state;
    if (SerialNo === "") {
      alert("Please provide SerialNo");
      return;
    }
    let Duplicate = false;
    SerialNoList.map((item) => {
      if (item.SerialNo === SerialNo) {
        Duplicate = true;
        return;
      }
    });
    !Duplicate
      ? SerialNoList.push({ SerialNo, Quantity })
      : alert("Dupliate serial no.");
    this.setState({ SerialNoList, SerialNo: "", Quantity: 1 });
  };
  addMuiltiple = () => {
    const { SerialNoList, setting } = this.state;
    let Current_Item_ID = Number(setting.Current_Item_ID);
    SerialNoList.map((Item) => {
      this.submit_handler(Item.SerialNo, Current_Item_ID, Item.Quantity);
      Current_Item_ID++;
    });
    this.props.firebase.setting().update({
      Current_Item_ID: Current_Item_ID,
    });
  };
  submit_handler = (SerialNo, NewItemID, Quantity) => {
    const {
      ID,
      Manufacturer,
      MFD,
      ModalName,
      BillingDate,
      Status,
      Type,
      Price,
      History,
      setting,
      StockStatus,
      Location,
      Update,
      ItemList,
      AddedItemList,
      SerialNoList,
    } = this.state;
    if (
      SerialNo.length &&
      Manufacturer.length &&
      ModalName.length &&
      BillingDate.length &&
      Status.length &&
      Type.length &&
      StockStatus.length
    ) {
      const ItemID = "ASRB00" + NewItemID;
      let Duplicate = false;
      ItemList.map((item) => {
        if (item.SerialNo === SerialNo) {
          Duplicate = true;
          return;
        }
      });
      !Duplicate
        ? this.props.firebase
            .getSingleInventoryItem(ItemID)
            .set({
              ID: ItemID,
              ModalName,
              SerialNo,
              Manufacturer,
              MFD,
              BillingDate,
              Status,
              Type,
              Price,
              Quantity,
              History,
              StockStatus,
              Location,
            })
            .then(() => {
              const NewAddedItemList = Number(this.state.AddedItemList) + 1;
              this.setState({ AddedItemList: NewAddedItemList });
              if (NewAddedItemList >= SerialNoList.length) {
                this.setState({ ...INITIAL_STATE });
                this.setState({ SerialNoList: [] });
                alert("All Items Added Successfully");
              }
            })
        : alert("This Serial no Already Exists");
    } else {
      alert("Please fill all the details.");
    }
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { classes } = this.props;
    const {
      Name,
      ID,
      ModalName,
      SerialNo,
      Manufacturer,
      MFD,
      BillingDate,
      Status,
      Parts,
      StockStatus,
      Type,
      Price,
      Quantity,
      ModalOption,
    } = this.state;
    return (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Multi Item Upload</h4>
          <p className={classes.cardCategoryWhite}>Fill All Details</p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Select Type</InputLabel>
                <Select
                  name="Type"
                  value={Type}
                  input={
                    <Input
                      classes={{ underline: classes.underline }}
                      name="Type"
                      value={Type}
                      onChange={this.onChange}
                    />
                  }
                >
                  <MenuItem value="Product">Product</MenuItem>
                  <MenuItem value="Accesories">Accesories</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Select Manufacturer</InputLabel>
                <Select
                  name="Manufacturer"
                  value={this.state.Manufacturer}
                  input={
                    <Input
                      classes={{ underline: classes.underline }}
                      name="Manufacturer"
                      onChange={this.onChange}
                    />
                  }
                >
                  <MenuItem value="Mindray">Mindray</MenuItem>
                  <MenuItem value="Fuji Films">Fuji Films</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="MFD"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "MFD",
                  type: "Date",
                  value: MFD,
                  onChange: this.onChange,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Billing Date / Shipping Date"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "BillingDate",
                  type: "date",
                  value: BillingDate,
                  onChange: this.onChange,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Warehouse Location</InputLabel>
                <Select
                  name="Location"
                  value={this.state.Location}
                  input={
                    <Input
                      classes={{ underline: classes.underline }}
                      name="Location"
                      onChange={this.onChange}
                    />
                  }
                >
                  <MenuItem value="Room 1">Room 1</MenuItem>
                  <MenuItem value="Room 2">Room 2</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={10} sm={10} md={2}>
              <FormControl className={classes.formControl} fullWidth>
                <Autocomplete
                  options={ModalOption}
                  getOptionLabel={(option) => option.Name}
                  style={{ width: 290 }}
                  inputValue={
                    this.state.Update ? ModalName : this.state.newInputValue
                  }
                  onInputChange={(event, newInputValue) => {
                    this.setState({ inputNEW: newInputValue });
                  }}
                  onChange={(event, newValue) => {
                    this.setState({
                      ModalName: newValue.Name,
                      CurrentSelected: newValue,
                    });
                  }}
                  value={this.state.CurrentSelected}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Modal Name"
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </GridItem>
            <GridItem xs={2} sm={2} md={1}>
              <FormControl className={classes.formControl} fullWidth>
                {" "}
                <Tooltip
                  title="Add New Modal"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link to="/admin/inventory/addmodal">
                    <Button color="info">+</Button>
                  </Link>
                </Tooltip>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  name="Status"
                  value={Status}
                  input={
                    <Input
                      classes={{ underline: classes.underline }}
                      name="Status"
                      onChange={this.onChange}
                    />
                  }
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem xs={12} sm={12} md={2}>
              <CustomInput
                labelText="Price"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "Price",
                  type: "text",
                  value: Price,
                  onChange: this.onChange,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomInput
                labelText="Quantity"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "Quantity",
                  type: "text",
                  value: Quantity,
                  onChange: this.onChange,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Stock Status</InputLabel>
                <Select
                  name="StockStatus"
                  value={StockStatus}
                  input={
                    <Input
                      classes={{ underline: classes.underline }}
                      name="StockStatus"
                      onChange={this.onChange}
                    />
                  }
                >
                  <MenuItem value="InStock">InStock</MenuItem>
                  <MenuItem value="OutStock">OutStock</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={3} sm={3} md={3}>
              <CustomInput
                labelText="SerialNo"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "SerialNo",
                  type: "Text",
                  value: SerialNo,
                  onChange: this.onChange,
                }}
              />
            </GridItem>
            <GridItem xs={3} sm={3} md={3}>
              <CustomInput
                labelText="Quantity"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "Quantity",
                  type: "Text",
                  value: Quantity,
                  onChange: this.onChange,
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Button onClick={this.addNewserial} color="primary">
                Add Serial No.
              </Button>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Manufacturer</TableCell>
                  </TableRow>
                </TableHead>
                {this.state.SerialNoList !== null
                  ? this.state.SerialNoList.map((row) => (
                      <TableBody>
                        <TableRow>
                          <TableCell>{row.SerialNo}</TableCell>
                          <TableCell>{row.Quantity}</TableCell>
                        </TableRow>
                      </TableBody>
                    ))
                  : null}
              </Table>
            </GridItem>
          </GridContainer>

          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={3}>
              <Button onClick={this.addMuiltiple} color="info">
                Submit
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(style)
)(AddNewItem);
