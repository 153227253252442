/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { withFirebase } from "layouts/Firebase";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { compose } from "recompose";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-react.jsx";
import { Modal } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + " !important",
      borderWidth: "1px !important",
    },
    "&:after": {
      borderColor: primaryColor[0],
    },
  },

  paper: {
    maxWidth: "70%",
    overflowY: "auto",
    margin: "auto",
  },
};

const INITIAL_STATE = {
  Date: "",
  Month: "",
  Year: "",
  Company: "",
  Call_Type: "",
  Details: "",
  Engineer_Name: "",
  Charge: "",
};
class TypographyPage extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  componentDidMount() {
    //Getting userlist
    this.props.firebase.users().on("value", (snapshot) => {
      const usersObject = snapshot.val();

      const usersList = Object.keys(usersObject).map((key) => ({
        ...usersObject[key],
        uid: key,
      }));
      const filterUser = usersList.filter((item) => item.role === "Engineer");
      this.setState({
        users: filterUser,
      });
    });

    //getting initial machine list from collection
    this.props.firebase.getMindrayIB().onSnapshot((snapshot) => {
      let CustomerList = [];

      snapshot.forEach((doc) => {
        CustomerList.push({
          ...doc.data(),
          ID: doc.id,
        });
      });

      this.setState({
        MindrayIB: CustomerList,
      });
    });
    this.props.firebase.getIB().onSnapshot((snapshot) => {
      let CustomerList = [];

      snapshot.forEach((doc) => {
        CustomerList.push({
          ...doc.data(),
          ID: doc.id,
        });
      });
      this.setState({
        FujiIB: CustomerList,
      });
    });
    //fetching calls from collection
    this.props.firebase.Calls().onSnapshot((snapshot) => {
      let CustomerList = [];
      //storing id in ID
      snapshot.forEach((doc) => {
        CustomerList.push({
          ...doc.data(),
          ID: doc.id,
        });
      });
      this.setState({
        AllCalls: CustomerList,
      });
    });
    // reading seting for counting and actual values value from realtime database
    this.props.firebase.setting().on("value", (snapshot) => {
      const setting = snapshot.val();
      this.setState({ setting });
    });
  }
  submit_handler = () => {
    const {
      Date,
      Month,
      Year,
      Company,
      Call_Type,
      Details,
      Engineer_Name,
      Charge,
      slectedIB,
      setting,
    } = this.state;
    const CallID = "ASRB" + setting.Current_Call_ID;
    let Ser_No = "";
    Company === "Mindray" ? (Ser_No = slectedIB[2]) : (Ser_No = slectedIB[2]);
    this.props.firebase
      .SingleCall(CallID)
      .set({
        Date,
        Month,
        Year,
        Company,
        Call_Type,
        Details,
        Engineer_Name,
        Charge,
        Ser_No,
        CustomerName: slectedIB[1],
        Contract_Type: slectedIB[4],
      })
      .then(() => {
        alert("Added Successfully");
        const newID = Number(setting.Current_Call_ID) + 1;
        this.props.firebase.setting().update({
          Current_Call_ID: newID,
        });
        this.setState({
          Date: "",
          Month: "",
          Year: "",
          Company: "",
          Call_Type: "",
          Details: "",
          Engineer_Name: "",
          Charge: "",
          slectedIB: [],
        });
      });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTableCell: {
          root: {
            padding: "5px",
          },
        },
      },
    });
  AssignCall = (ID) => {
    this.state.AllCalls.map((item) => {
      if (item.ID === ID)
        this.setState({ CurrentAssinedCall: item, OpenAssignDialog: true });
    });
  };
  AssignCallFinale = () => {
    const { CurrentAssinedCall, AssinedEngineer } = this.state;
    this.props.firebase
      .SingleCall(CurrentAssinedCall.ID)
      .update({
        Engineer_Name: AssinedEngineer,
      })
      .then(() => {
        alert("Assined successfully");
        this.setState({
          CurrentAssinedCall: null,
          OpenAssignDialog: false,
          AssinedEngineer: "",
        });
      });
  };
  render() {
    // columns for muidatatable
    const columns = [
      {
        name: "ID",
        label: "ID",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Customer Name",
        label: "Customer Name",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Machine_Ser_NO",
        label: "Serial No.",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Ser_No",
        label: "Serial No.",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Contract Type",
        label: "Contract Type",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];
    const columns2 = [
      {
        name: "ID",
        label: "ID",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "CustomerName",
        label: "Customer Name",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Ser_No",
        label: "Serial No.",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Engineer_Name",
        label: "Engineer Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Call_Type",
        label: "Call Type",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "CallStatus",
        label: "Status",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Company",
        label: "Company",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "Month",
        label: "Month",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Year",
        label: "Year",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Contract_Type",
        label: "Contract Type",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "Charge",
        label: "Charge",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "Details",
        label: "Details",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "ID",
        label: "Assign",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button onClick={() => this.AssignCall(value)}>Assign</Button>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      download: false,
      rowsPerPage: 15,
      selectableRows: "none",
      download: true,
      onRowClick: (rowData, rowState) => {
        this.setState({ slectedIB: rowData, modalOpen: false });
      },
      print: false,
      customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
        <div></div>
      ),
    };

    const options2 = {
      filterType: "checkbox",
      download: false,
      rowsPerPage: 15,
      selectableRows: "none",
      print: true,
      responsive: "none",
    };
    const { classes } = this.props;
    let {
      Company,
      MindrayIB,
      FujiIB,
      SR_No,
      CRM_No,
      Part_Replaced,
      Name,
    } = this.state;

    return (
      <>
        <Card>
          <Modal
            open={this.state.modalOpen}
            onClose={() => this.setState({ modalOpen: false })}
            className={classes.paper}
          >
            <div className={classes.Table}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Select the Machine</h4>
                </CardHeader>
                <CardBody>
                  <MUIDataTable
                    data={Company === "Mindray" ? MindrayIB : FujiIB}
                    columns={columns}
                    options={options}
                  />
                </CardBody>
              </Card>
            </div>
          </Modal>
          <Modal
            open={this.state.FillUP}
            onClose={() => this.setState({ FillUP: false })}
            className={classes.paper}
          >
            <div className={classes.Table}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>
                    Assign Call To Engineer
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Service Report No."
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "SR_No",
                          value: SR_No,
                          onChange: this.onChange,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="CRM No."
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          value: CRM_No,
                          onChange: this.onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Part Replaced</InputLabel>
                        <Select
                          name="Part_Replaced"
                          value={this.state.Part_Replaced}
                          input={
                            <Input
                              classes={{ underline: classes.underline }}
                              name="Part_Replaced"
                              onChange={this.onChange}
                            />
                          }
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  {Part_Replaced ? (
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="CRM No."
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: CRM_No,
                            onChange: this.onChange,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="CRM No."
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: CRM_No,
                            onChange: this.onChange,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="CRM No."
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: CRM_No,
                            onChange: this.onChange,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControl className={classes.formControl} fullWidth>
                          <InputLabel>Part Source </InputLabel>
                          <Select
                            name="Part_Source"
                            value={this.state.Part_Source}
                            input={
                              <Input
                                classes={{ underline: classes.underline }}
                                name="Part_Source"
                                onChange={this.onChange}
                              />
                            }
                          >
                            <MenuItem value="FromCompany">FromCompany</MenuItem>
                            <MenuItem value="FromInventoryStock">
                              FromInventoryStock
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  ) : null}
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Full Name"
                        id="first-name"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: "text",
                          value: Name,
                          onChange: this.onChange,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </div>
          </Modal>
          <Modal
            open={this.state.OpenAssignDialog}
            onClose={() => this.setState({ OpenAssignDialog: false })}
            className={classes.paper}
          >
            <div className={classes.Table}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>
                    Assign Call To Engeneer
                  </h4>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={10} sm={10} md={4}>
                      <FormControl className={classes.formControl} fullWidth>
                        <InputLabel>Warehouse Location</InputLabel>

                        <Select
                          name="AssinedEngineer"
                          value={this.state.Location}
                          input={
                            <Input
                              classes={{ underline: classes.underline }}
                              name="AssinedEngineer"
                              onChange={this.onChange}
                            />
                          }
                        >
                          {this.state.users &&
                            this.state.users.map((item) => (
                              <MenuItem value={item.EmpID + " " + item.Name}>
                                {item.EmpID} {item.Name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <Button onClick={() => this.AssignCallFinale()}>
                    Assign
                  </Button>
                </CardBody>
              </Card>
            </div>
          </Modal>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Add New Call</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={4} sm={4} md={2}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Date</InputLabel>
                  <Select
                    name="Date"
                    value={this.state.Date}
                    input={
                      <Input
                        classes={{ underline: classes.underline }}
                        name="Date"
                        onChange={this.onChange}
                      />
                    }
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                    <MenuItem value="24">24</MenuItem>
                    <MenuItem value="25">25</MenuItem>
                    <MenuItem value="26">26</MenuItem>
                    <MenuItem value="27">27</MenuItem>
                    <MenuItem value="28">28</MenuItem>
                    <MenuItem value="29">29</MenuItem>
                    <MenuItem value="30">30</MenuItem>
                    <MenuItem value="31">31</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={4} sm={4} md={2}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Month</InputLabel>
                  <Select
                    name="Month"
                    value={this.state.Month}
                    input={
                      <Input
                        classes={{ underline: classes.underline }}
                        name="Month"
                        onChange={this.onChange}
                      />
                    }
                  >
                    <MenuItem value="1">1</MenuItem>
                    <MenuItem value="2">2</MenuItem>
                    <MenuItem value="3">3</MenuItem>
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={4} sm={4} md={2}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Year</InputLabel>
                  <Select
                    name="Year"
                    value={this.state.Year}
                    input={
                      <Input
                        classes={{ underline: classes.underline }}
                        name="Year"
                        onChange={this.onChange}
                      />
                    }
                  >
                    <MenuItem value="2019">2019</MenuItem>
                    <MenuItem value="2020">2020</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={4} sm={4} md={2}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Select Company</InputLabel>
                  <Select
                    name="Company"
                    value={this.state.Company}
                    input={
                      <Input
                        classes={{ underline: classes.underline }}
                        name="Company"
                        onChange={this.onChange}
                      />
                    }
                  >
                    <MenuItem value="Mindray">Mindray</MenuItem>
                    <MenuItem value="Fuji Films">Fuji Films</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={5}>
                <Button
                  color="info"
                  onClick={() => this.setState({ modalOpen: true })}
                >
                  Select Customer
                </Button>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel>Call Type</InputLabel>
                  <Select
                    name="Call_Type"
                    value={this.state.Call_Type}
                    input={
                      <Input
                        classes={{ underline: classes.underline }}
                        name="Call_Type"
                        onChange={this.onChange}
                      />
                    }
                  >
                    <MenuItem value="PM">P M</MenuItem>
                    <MenuItem value="CR">C R</MenuItem>
                    <MenuItem value="Chargeable">chargeable</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Call Details"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "Details",
                    type: "text",
                    value: this.state.Details,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Engineer Name"
                  id="email-address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "Engineer_Name",
                    type: "text",
                    value: this.state.Engineer_Name,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Charge(If Any)"
                  id="email-address"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    name: "Charge",
                    type: "text",
                    value: this.state.Charge,
                    onChange: this.onChange,
                  }}
                />
              </GridItem>
            </GridContainer>

            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={3}>
                <Button onClick={this.submit_handler} color="info">
                  Submit
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            data={this.state.AllCalls}
            columns={columns2}
            options={options2}
          />
        </MuiThemeProvider>
      </>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(style)
)(TypographyPage);
