import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { withFirebase } from "layouts/Firebase";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Modal,
} from "@material-ui/core";
import CreateTicket from "./CreateTicket";
import Button from "components/CustomButtons/Button.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import { PDFViewer } from "@react-pdf/renderer";
import Invoice from "./Component/reports/Invoice";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  paper: {
    maxWidth: "70%",
    overflowY: "auto",
    margin: "auto",
  },
};

class SingleTicket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      item: null,
      List: null,
      OpenEdit: false,
      OpenCencle: false,
      currentItemID: "",
      returnList: [],
      Items: [],
    };
  }

  componentDidMount() {
    this.props.firebase
      .getSingleTicket(this.props.match.params.id)
      .get()
      .then((doc) => {
        const Newdata = { ID: doc.id, ...doc.data() };

        this.setState({ item: Newdata, List: Newdata.SellItemsList });
        const Items = [];
        let sno = 1;
        Newdata.SellItemsList.map((item) => {
          Items.push({
            id: item.ID,
            sno,
            desc: `${item.ModalName}  (${item.SerialNo})`,
            qty: item.SellQuantity,
            rate: 0,
          });
          sno++;
        });
        this.setState({ Items });
      });
  }

  addNewItem = () => {
    let tempList = this.state.returnList;
    let tempitem = null;
    this.state.List.map((item) => {
      if (item.ID === this.state.currentItemID) tempitem = item;
    });
    tempList.push({ ...tempitem });

    this.setState({ returnList: tempList });
  };

  StartReturnList = () => {
    const { returnList, item } = this.state;
    let ReturnList = [];
    let TempList = this.state.List;
    if (item.ReturnList) ReturnList = item.ReturnList;
    let NewDate = "";
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    NewDate = `${year}-${month}-${date}`;
    returnList.map((Item) => {
      ReturnList.push(Item);
      let index = -1;
      TempList.find((Item, i) => {
        if (item.ID === Item.ID) {
          index = i;
          return;
        }
      });
      TempList.splice(index, 1);
      this.props.firebase
        .getSingleInventoryItem(Item.ID)
        .get()
        .then((doc) => {
          const currentItem = doc.data();
          let History = [];
          if (currentItem.History && currentItem.History !== undefined)
            History = currentItem.History;
          History.push({
            Date: NewDate,
            Ticket_ID: item.ID,
            Details: `Ticket Cancled, ${Item.SellQuantity} items from ${item.Customer_Details}`,
          });
          let NewQuantity = 1;
          if (currentItem.Type === "Accesories") {
            NewQuantity =
              Number(currentItem.Quantity) + Number(Item.SellQuantity);
          }
          this.props.firebase.getSingleInventoryItem(Item.ID).update({
            Quantity: NewQuantity,
            StockStatus: "InStock",
            //History,
          });
        });
    });
    this.props.firebase
      .getSingleTicket(this.props.match.params.id)
      .update({
        ReturnList,
        SellItemsList: TempList,
      })
      .then(() => {
        alert("Items Restocked Successfully ");
        this.setState({
          OpenCencle: false,
          returnList: [],
        });
      });
  };
  CencleTicket = () => {
    const { item } = this.state;
    let currentItem = null;
    this.state.item.SellItems.map((Item) => {
      this.props.firebase
        .getSingleInventoryItem(Item.ID)
        .get()
        .then((doc) => {
          const Newdata = doc.data();
          currentItem = Newdata;
        });
      let History = [];
      let StockStatus = "Sold";
      let newQuantity = 0;
      let Details = `Ticket Cancled ${item.Customer_Details}`;
      if (currentItem.History && currentItem.History !== undefined)
        History = currentItem.History;
      if (currentItem.Type === "Accesories") {
        newQuantity = currentItem.Quantity + Item.SellQuantity;
        newQuantity === 0
          ? (StockStatus = "InStock")
          : (StockStatus = "InStock");
        Details = `Ticket Cancled, ${Item.SellQuantity} from ${item.Customer_Details}`;
      }
      History.push({
        Date,
        Ticket_ID: item.ID,
        Details,
      });
      this.props.firebase.getSingleInventoryItem(Item.ID).update({
        Quantity: newQuantity,
        StockStatus,
        History,
      });
      this.props.firebase
        .getSingleTicket(this.state.item.ID)
        .update({ Status: "Cancled" });
      return "Done";
    });
  };

  CloseTicket = () => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    this.props.firebase
      .getSingleTicket(this.props.match.params.id)
      .update({
        Status: "Closed",
        ClosingDate: `${year}-${month}-${date}`,
      })
      .then(() => {
        alert("Ticket Closed ");
      });
  };
  onChange = (event) => {
    this.setState({ currentItemID: event.target.value });
  };
  CreateInvoice = () => {
    const Invoice = {
      id: "random",
      invoice_no: this.state.item.CRM_No,
      balance: "$2,283.74",
      company: this.state.item.Customer_Details,
      email: "",
      phone: this.state.item.Customer_Contact,
      address: "",
      trans_date: this.state.item.Date,
      due_date: "2019-10-12",
      cgst: this.state.cgst,
      items: this.state.Items,
    };
    this.setState({ Invoice, OpenInvoice: false });
  };

  OnChangeItem = (id, value, type) => {
    let TempItems = [];
    TempItems.push(...this.state.Items);
    let index = -1;
    let TempData = [];
    TempItems.find((item, i) => {
      if (item.id === id) {
        index = i;
        if (type === "desc") item.desc = value;
        if (type === "qty") item.qty = value;
        if (type === "rate") item.rate = value;
        TempData = item;
        return;
      }
    });
    //TempItems.splice(index, 1);

    this.setState({ Items: TempItems });
  };

  SendMail = () => {
    const {
      ID,
      TicketStatus,
      Date,
      ItemType,
      TicketType,
      Customer_Details,
      Customer_Contact,
      Engineer_Name,
      Authorise_Person,
      Price,
      Expected_Return,
      Manual_Recipt,
      Quantity,
      CRM_No,
      Parts,
      SellItemsList,
    } = this.state.item;
    let Table =
      "<table><tr><th>ID</th><th>SerialNo</th><th>ModalName</th><th>SellQuantity</th></tr>";
    SellItemsList.map((item) => {
      Table += `<tr><th>${item.ID}</th><th>${item.SerialNo}</th> <th>${item.ModalName}</th><th>${item.SellQuantity}</th></tr>`;
    });
    Table += "</table>";
    var template_params = {
      from: "crm@asrbmedicalservices.com",
      to:
        "asrbcrm@gmail.com,srivastav.bk1957@gmail.com,asrbmedicalservices@gmail.com,asrbcoordinator@gmail.com,rahul121azad@gmail.com",
      subject: `Ticket Creation Alert ID : ${ID}`,
      html: `New Ticket has been created here are the details
       <b> TicketID :-</b> ${ID},<br/>
       <b> Status:-</b>  ${TicketStatus},<br/>
       <b> Date:-</b>  ${Date},<br/>
       <b> ItemType:-</b> ${ItemType},<br/>
       <b> TicketType:-</b> ${TicketType},<br/>
       <b> Customer Details:-</b> ${Customer_Details},<br/>
       <b> Customer Contact:-</b> ${Customer_Contact},<br/>
       <b> Engineer Name:-</b> ${Engineer_Name},<br/>
       <b> Authorise Person:-</b> ${Authorise_Person},<br/>
       <b> Price:-</b> ${Price},<br/>
       <b> Expected Return:-</b> ${Expected_Return},<br/>
       <b> Manual Recipt:-</b> ${Manual_Recipt},<br/>
       <b> Quantity :-</b> ${Quantity},<br/>
       <b> CRM No:-</b> ${CRM_No},<br/>
       <b> Parts:-</b> ${JSON.stringify(Parts)},<br/>
       <b> SellItemsList:-</b> ${Table}, <br/>`,
    };

    fetch("https://www.ranjansingh.dev/api/email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(template_params),
    });
    alert("Email Sent Successfully");
  };

  render() {
    const { classes } = this.props;
    const { item, OpenCencle, OpenEdit, returnList, OpenInvoice } = this.state;
    if (item !== null)
      return (
        <Card>
          <Modal
            open={OpenInvoice}
            onClose={() => this.setState({ OpenInvoice: false })}
            className={classes.paper}
          >
            <Card>
              <GridContainer>
                {this.state.Items.map((item) => (
                  <>
                    <GridItem xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Input
                        border={0.5}
                        className={classes.inputDetail}
                        placeholder="Part No*"
                        required
                        value={item.desc}
                        onChange={(e) =>
                          this.OnChangeItem(item.id, e.target.value, "desc")
                        }
                      />
                    </GridItem>
                    <GridItem xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Input
                        border={0.5}
                        className={classes.inputDetail}
                        placeholder="Quantity*"
                        required
                        value={item.qty}
                        onChange={(e) =>
                          this.OnChangeItem(item.id, e.target.value, "qty")
                        }
                      />
                    </GridItem>
                    <GridItem xl={4} lg={4} md={4} sm={4} xs={12}>
                      <Input
                        border={0.5}
                        className={classes.inputDetail}
                        placeholder="Rate*"
                        required
                        value={item.rate}
                        onChange={(e) =>
                          this.OnChangeItem(item.id, e.target.value, "rate")
                        }
                      />
                    </GridItem>
                  </>
                ))}
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>Select CGST & SGST </InputLabel>
                    <Select
                      name="cgst"
                      value={this.state.cgst}
                      input={
                        <Input
                          classes={{ underline: classes.underline }}
                          name="cgst"
                          onChange={(e) =>
                            this.setState({ cgst: e.target.value })
                          }
                        />
                      }
                    >
                      <MenuItem value="2.5">2.5%</MenuItem>
                      <MenuItem value="6">6%</MenuItem>
                      <MenuItem value="9">9%</MenuItem>
                      <MenuItem value="14">14%</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <Button color="info" onClick={() => this.CreateInvoice()}>
                Generate Invoice
              </Button>
            </Card>
          </Modal>

          <Modal
            open={OpenEdit}
            onClose={() => this.setState({ OpenEdit: false })}
            className={classes.paper}
          >
            <CreateTicket Ticket={item} edit={true} />
          </Modal>

          <Modal
            open={OpenCencle}
            onClose={() => this.setState({ OpenCencle: false })}
            className={classes.paper}
          >
            <Card>
              <CardHeader color="primary">
                Ticket ID : {item.ID} Status:{item.Status}
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={10} sm={10} md={4}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel>Parts/Items to return </InputLabel>

                      <Select
                        name="Location"
                        input={
                          <Input
                            classes={{ underline: classes.underline }}
                            name="Location"
                            onChange={this.onChange}
                          />
                        }
                      >
                        {item.SellItemsList &&
                          item.SellItemsList.map((item) => (
                            <MenuItem value={item.ID}>
                              {item.ID} {item.ModalName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <Button onClick={() => this.addNewItem()}>add</Button>
                  </GridItem>
                </GridContainer>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item ID</TableCell>
                      <TableCell>SerialNo</TableCell>
                      <TableCell>ModalName</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  {returnList &&
                    returnList.map((part) => (
                      <TableBody>
                        <TableCell>{part.ID}</TableCell>
                        <TableCell>{part.SerialNo}</TableCell>
                        <TableCell>{part.ModalName}</TableCell>
                        <TableCell>{part.SellQuantity}</TableCell>
                      </TableBody>
                    ))}
                </Table>
                {returnList.length && (
                  <Button color="danger" onClick={() => this.StartReturnList()}>
                    Start Return
                  </Button>
                )}
              </CardBody>
            </Card>
          </Modal>
          <CardHeader color="primary">
            Ticket ID : {item.ID} Status:{item.Status}
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={6} sm={6} md={6}>
                <GridItem>Authorise Person : {item.Authorise_Person}</GridItem>
                <GridItem>Customer_Contact : {item.Customer_Contact}</GridItem>
                <GridItem>Engineer_Name : {item.Engineer_Name}</GridItem>
                <GridItem>Expected Return : {item.Expected_Return}</GridItem>
                <GridItem> ItemType : {item.ItemType}</GridItem>
                <GridItem>TicketType : {item.TicketType}</GridItem>
                <GridItem>
                  <Button
                    color="danger"
                    onClick={() => this.setState({ OpenCencle: true })}
                  >
                    return items
                  </Button>
                  <Button
                    color="info"
                    onClick={() => this.setState({ OpenEdit: true })}
                  >
                    Edit
                  </Button>
                  <Button
                    color="info"
                    onClick={() => this.setState({ OpenInvoice: true })}
                  >
                    Invoice
                  </Button>
                  <Button color="info" onClick={() => this.SendMail()}>
                    Send Email
                  </Button>
                </GridItem>
              </GridItem>
              <GridItem xs={6} sm={6} md={6}>
                <GridItem>Customer Details :{item.Customer_Details}</GridItem>
                <GridItem>Manual Recipt:{item.Manual_Recipt}</GridItem>
                <GridItem>Quantity : {item.Quantity}</GridItem>
                <GridItem>CRM NO :{item.CRM_No}</GridItem>
                <GridItem>Price :{item.Price}</GridItem>
              </GridItem>
              <GridItem
                style={{ boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)" }}
                xs={6}
                sm={6}
                md={6}
              >
                ItemList
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item ID</TableCell>
                      <TableCell>SerialNo</TableCell>
                      <TableCell>ModalName</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  {item.SellItemsList &&
                    item.SellItemsList.map((part) => (
                      <TableBody>
                        <TableCell>
                          <Link to={`/admin/Inventory/${part.ID}`}>
                            {part.ID}
                          </Link>
                        </TableCell>
                        <TableCell>{part.SerialNo}</TableCell>
                        <TableCell>{part.ModalName}</TableCell>
                        <TableCell>{part.SellQuantity}</TableCell>
                      </TableBody>
                    ))}
                </Table>
              </GridItem>
              <GridItem
                style={{ boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)" }}
                xs={6}
                sm={6}
                md={6}
              >
                ReturnList
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item ID</TableCell>
                      <TableCell>SerialNo</TableCell>
                      <TableCell>ModalName</TableCell>
                      <TableCell>Details</TableCell>
                    </TableRow>
                  </TableHead>
                  {item.ReturnList &&
                    item.ReturnList.map((part) => (
                      <TableBody>
                        <TableCell>
                          <Link to={`/admin/Inventory/${part.ID}`}>
                            {part.ID}
                          </Link>
                        </TableCell>
                        <TableCell>{part.SerialNo}</TableCell>
                        <TableCell>{part.ModalName}</TableCell>
                        <TableCell>{part.SellQuantity}</TableCell>
                      </TableBody>
                    ))}
                </Table>
              </GridItem>
            </GridContainer>
            {this.state.Invoice ? (
              <PDFViewer width="1000" height="600" className="app">
                <Invoice invoice={this.state.Invoice} />
              </PDFViewer>
            ) : null}
          </CardBody>
        </Card>
      );
    else return <Card></Card>;
  }
}

export default compose(
  withFirebase,
  withStyles(styles)
)(SingleTicket);
