import React from "react";
import { Switch, Route } from "react-router-dom";
import List from "./TableList";
import Equpment from "./EqupmentDetails";
const index = () => {
  return (
    <Switch>
      <Route exact path="/admin/mindray" component={List} />
      <Route path="/admin/mindray/:id" component={Equpment} />
    </Switch>
  );
};

export default index;
