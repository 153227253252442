import React, { Component } from "react";
import { compose } from "recompose";
import { withFirebase } from "layouts/Firebase";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Modal from "@material-ui/core/Modal";
import AddNewEquipment from "./AddNewEquipment";
import Button from "components/CustomButtons/Button.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  paper: {
    maxWidth: "70%",
    overflowY: "auto",
    margin: "auto"
  }
};
const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "text.primary",
  m: 1,
  border: 2,
  style: { padding: 20 }
};

class EquipmnetDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CustomerList: [],
      FilterdCustomer: [],
      SelectedIB: null,
      ContractHistory: null,
      loading: true,
      NewContractType: "",
      NewStartDate: "",
      NewEndDate: "",
      NewContractAmount: ""
    };
  }
  componentDidMount() {
    this.props.firebase.getIB().onSnapshot(snapshot => {
      let CustomerList = [];

      snapshot.forEach(doc => {
        CustomerList.push({
          ...doc.data(),
          ID: doc.id
        });
      });
      CustomerList.map(item => {
        if (item.ID === this.props.match.params.id) {
          this.setState({
            SelectedIB: item
          });
          if (item.ContractHistory)
            this.setState({
              ContractHistory: JSON.parse(item.ContractHistory)
            });
        }
      });
      this.setState({
        CustomerList,
        FilterdCustomer: CustomerList,
        loading: false
      });
    });
  }
  componentWillUnmount() {
    this.setState({ ContractHistory: null });
  }
  deleteIB = () => {
    this.props.firebase
      .getSingleIB(this.props.match.params.id)
      .delete()
      .then(() => this.props.history.push("/admin/mindray"));
  };
  addNewCall = () => {
    const { Date, Type, Details, contractType, SelectedIB } = this.state;
    if ((Type.length, Details.length, contractType.length)) {
      let C_R = [];
      if (SelectedIB.C_R !== null && SelectedIB.C_R !== "")
        C_R = SelectedIB.C_R;

      C_R.push({
        Date,
        Type,
        Details,
        contractType
      });
      this.props.firebase.getSingleIB(this.state.ID).update({
        C_R
      });
    } else {
      alert("Fill All Fields");
    }
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  updateContract = () => {
    const {
      SelectedIB,
      NewContractType,
      NewStartDate,
      NewEndDate,
      NewContractAmount,
      NewInvoice
    } = this.state;
    if (
      NewContractType.length &&
      NewStartDate.length &&
      NewEndDate.length &&
      NewContractAmount.length
    ) {
      let ContractHistory = [];
      if (SelectedIB.ContractHistory)
        ContractHistory = JSON.parse(SelectedIB.ContractHistory);
      ContractHistory.push({
        StartDate: SelectedIB["StartDate"],
        ContractType: SelectedIB["Contract Type"],
        EndDate: SelectedIB["EndDate"],
        ContractAmount: SelectedIB["Contract Amount"],
        Invoice: SelectedIB["Invoice number"]
      });
      this.props.firebase
        .getSingleIB(SelectedIB.ID)
        .update({
          "Contract Type": NewContractType,
          StartDate: NewStartDate,
          EndDate: NewEndDate,
          "Contract Price": NewContractAmount,
          "Invoice number": NewInvoice,
          ContractHistory: JSON.stringify(ContractHistory)
        })
        .then(() => alert("Updated Contract"));
      this.setState({
        NewContractType: "",
        NewStartDate: "",
        NewEndDate: "",
        NewContractAmount: "",
        modalUpdateContract: false
      });
    } else {
      alert("Please fill all the details");
    }
  };
  render() {
    const { classes } = this.props;
    const { SelectedIB } = this.state;
    return (
      <div>
        <Modal
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          className={classes.paper}
        >
          <AddNewEquipment SelectedIB={this.state.SelectedIB} />
        </Modal>
        <Modal
          open={this.state.modalOpen1}
          onClose={() => this.setState({ modalUpdateContract: false })}
          className={classes.paper}
        >
          <Card>
            <CardHeader color="info">Provide new Contract Details</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={2}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>Contract Type</InputLabel>
                    <Select
                      name="NewContractType"
                      value={this.state.NewContractType}
                      input={
                        <Input
                          classes={{ underline: classes.underline }}
                          name="NewContractType"
                          onChange={this.onChange}
                        />
                      }
                    >
                      <MenuItem value="AMC">AMC</MenuItem>
                      <MenuItem value="CMC">CMC</MenuItem>
                      <MenuItem value="Warranty">Warranty</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <CustomInput
                    labelText="New StartDate"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "NewStartDate",
                      value: this.state["NewStartDate"],
                      type: "date",
                      onChange: this.onChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="New EndDate"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "NewEndDate",
                      type: "date",
                      value: this.state["NewEndDate"],
                      onChange: this.onChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Contract Amount"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "NewContractAmount",
                      type: "text",
                      value: this.state["NewContractAmount"],
                      onChange: this.onChange
                    }}
                  />
                  <CustomInput
                    labelText="New Invoice"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      name: "NewInvoice",
                      type: "date",
                      value: this.state["NewInvoice"],
                      onChange: this.onChange
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button color="info" onClick={() => this.updateContract()}>
                Update
              </Button>
              <Button
                onClick={() => this.setState({ modalUpdateContract: false })}
              >
                cencel
              </Button>
            </CardBody>
          </Card>
        </Modal>

        <Modal
          open={this.state.modalDeleteOpen}
          onClose={() => this.setState({ modalDeleteOpen: false })}
          className={classes.paper}
        >
          <Card>
            <CardHeader color="danger">
              Do you want to Delete This IB / Customer ?
            </CardHeader>
            <CardBody>
              <Button color="danger" onClick={this.deleteIB}>
                Yes Delete IT
              </Button>
              <Button onClick={() => this.setState({ modalDeleteOpen: false })}>
                cencel
              </Button>
            </CardBody>
          </Card>
        </Modal>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Customer Details</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          {!this.state.loading ? (
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <GridContainer
                    style={{
                      boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)",
                      borderRadius: 5
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <GridItem xs={6} sm={6} md={6}>
                      <p>Customer Code</p>
                      <p>Customer Name</p>
                      <p>Clinic Name</p>
                      <p>Address</p>
                      <p>Phone No.</p>
                      <p>City</p>
                      <p>District</p>
                      <p>Pincode</p>
                      <p>Region</p>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <p>:{SelectedIB["Customer Code"]}</p>
                      <p>:{SelectedIB["Customer Name"]}</p>
                      <p>:{SelectedIB["Customer/ Clinic Name"]}</p>
                      <p>:{SelectedIB["Address"]}</p>
                      <p>:{SelectedIB["Phone No"]}</p>
                      <p>:{SelectedIB["City"]}</p>
                      <p>:{SelectedIB["District"]}</p>
                      <p>:{SelectedIB["Pin code"]}</p>
                      <p>:{SelectedIB["Region"]}</p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <GridContainer
                    style={{
                      boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)",
                      borderRadius: 5
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <GridItem xs={6} sm={6} md={6}>
                      <p>Equipment Name</p>
                      <p>Invoice Number</p>
                      <p>Serial No.</p>
                      <p>End Date</p>
                      <p>Modal</p>
                      <p>Contract Type</p>
                    </GridItem>
                    {SelectedIB !== null ? (
                      <GridItem xs={6} sm={6} md={6}>
                        <p>:{SelectedIB["Equipment"]}</p>
                        <p>:{SelectedIB["Invoice number"]}</p>
                        <p>:{SelectedIB["Ser_No"]}</p>
                        <p>:{SelectedIB["EndDate"]}</p>
                        <p>:{SelectedIB["Model"]}</p>
                        <p>:{SelectedIB["Contract Type"]}</p>
                      </GridItem>
                    ) : (
                      <p>Loading</p>
                    )}
                  </GridContainer>
                  <Button
                    color="success"
                    onClick={() => this.setState({ modalOpen1: true })}
                  >
                    Update Contract
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.setState({ modalOpen: true })}
                  >
                    Edit Details
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => this.setState({ modalDeleteOpen: true })}
                  >
                    Delete IB
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <Modal
                  open={this.state.NewCallModal}
                  onClose={() => this.setState({ NewCallModal: false })}
                >
                  <Card>
                    <CardHeader color="primary">
                      <h4 className={classes.cardTitleWhite}>Add New Call</h4>
                      <p className={classes.cardCategoryWhite}></p>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Date"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              name: "Date",
                              type: "date",
                              onChange: this.onChange
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Call Type"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              name: "Type",
                              type: "text",
                              onChange: this.onChange
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Details"
                            id="email-address"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              name: "Details",
                              type: "text",
                              onChange: this.onChange
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Current Contract Type"
                            id="email-address"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              name: "contractType",
                              type: "text",
                              onChange: this.onChange
                            }}
                          />
                          <Button onClick={this.addNewCall}>Sumit</Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </Modal>
                <GridItem xs={6} sm={6} md={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Call Type</TableCell>
                        <TableCell>Call Details</TableCell>
                      </TableRow>
                    </TableHead>
                    {SelectedIB.C_R
                      ? SelectedIB.C_R.map(Item => (
                          <TableBody>
                            <TableCell>{Item.Date}</TableCell>
                            <TableCell>{Item.Type}</TableCell>
                            <TableCell>{Item.ContractType}</TableCell>
                            <TableCell>{Item.Details}</TableCell>
                          </TableBody>
                        ))
                      : null}
                  </Table>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Button
                    disabled
                    color="info"
                    onClick={() => this.setState({ NewCallModal: true })}
                  >
                    Add Contract History
                  </Button>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Contract Type</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Contract Price</TableCell>
                      </TableRow>
                    </TableHead>
                    {this.state.ContractHistory !== null &&
                    this.state.ContractHistory !== ""
                      ? SelectedIB.ContractHistory.map(Item => (
                          <TableBody>
                            <TableCell>{Item.Date}</TableCell>
                            <TableCell>{Item.Type}</TableCell>
                            <TableCell>{Item.ContractType}</TableCell>
                            <TableCell>{Item.Details}</TableCell>
                          </TableBody>
                        ))
                      : null}
                  </Table>
                </GridItem>
              </GridContainer>
            </CardBody>
          ) : (
            <CircularProgress className={classes.progress} />
          )}
        </Card>
      </div>
    );
  }
}
export default compose(
  withFirebase,
  withStyles(styles)
)(EquipmnetDetails);
