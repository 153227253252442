import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LandingPage from "../Landing";
import SignUpPage from "../SignUp";
import SignInPage from "../SignIn";
import Admin from "../Admin.jsx";
import { withAuthentication } from "../Session";
import { withStore } from "react-context-hook";
import { compose } from "recompose";

const storeConfig = {
  listener: state => {
    console.log("state changed", state);
  },
  logging: true //process.env.NODE_ENV !== 'production'
};
const initialState = { count: 10 };

const App = ({ hist }) => (
  <Router history={hist}>
    <Switch>
      <Route exact path="/" component={Admin} />
      <Route path="/signup" component={SignUpPage} />
      <Route path="/signin" component={SignInPage} />
      <Route path="/admin" component={Admin} />
    </Switch>
  </Router>
);
const NewApp = withStore(App, initialState, storeConfig);
export default withAuthentication(NewApp);
