import React from "react";
import { Switch, Route } from "react-router-dom";
import ListItems from "./ListItems";
import SingleItem from "./SingleItem";
import ListTeckets from "./ListTickets";
import SingleTicket from "./SingleTicket";
import AddModalName from "./AddModalName";
import AddStoreLocation from "./AddStoreLocation";
import AddPartName from "./AddPartName.jsx";
import AddMultipleItem from "./AddMultipleItem.jsx";
const index = () => {
  return (
    <Switch>
      <Route exact path="/admin/Inventory" component={ListItems} />
      <Route
        exact
        path="/admin/Inventory/AddMultiple"
        component={AddMultipleItem}
      />
      <Route exact path="/admin/Inventory/AddModal" component={AddModalName} />
      <Route
        exact
        path="/admin/Inventory/AddStoreLocation"
        component={AddStoreLocation}
      />
      <Route exact path="/admin/Inventory/AddPart" component={AddPartName} />
      <Route path="/admin/Inventory/:id" component={SingleItem} />
      <Route exact path="/admin/Tickets" component={ListTeckets} />
      <Route path="/admin/Tickets/:id" component={SingleTicket} />
    </Switch>
  );
};

export default index;
