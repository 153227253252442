import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  invoiceNoContainer: {
    flexDirection: "row",
    marginTop: 20,
    justifyContent: "flex-end",
  },
  invoiceDateContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold",
  },
  label: {
    width: 60,
  },
  headerContainerTop: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerContainer: {
    marginTop: 20,
  },
});

const InvoiceNo = ({ invoice }) => (
  <Fragment>
    <View style={styles.headerContainerTop}>
      <View style={styles.headerContainer}>
        <Text style={{ fontWeight: "bold" }}>MED SOLUTIONS</Text>
        <Text style={{ fontSize: 7 }}>
          (A UNIT OF RAMANS HEALTHCARE & DIAGNOSTIC PVT LTD)
        </Text>
        <Text style={{ fontSize: 9 }}>
          R NO 12, Rajender Nagar, Patna- 800016
        </Text>
        <Text style={{ fontSize: 9 }}>TEL - 0612 2662972 MOB 7707011119 </Text>
        <Text style={{ fontSize: 9 }}>GSTIN/UIN 10AAICR5618N1Z7</Text>
      </View>
      <View style={{}}>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Invoice No:</Text>
          <Text style={styles.invoiceDate}>{invoice.invoice_no}</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Date: </Text>
          <Text>{invoice.trans_date}</Text>
        </View>
      </View>
    </View>
  </Fragment>
);

export default InvoiceNo;
