import React, { Component } from "react";
import { compose } from "recompose";
import { withFirebase } from "layouts/Firebase";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import AddNewEquipment from "./AddNewEquipment";
import Button from "components/CustomButtons/Button.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import {
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-react.jsx";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  paper: {
    maxWidth: "70%",
    overflowY: "auto",
    margin: "auto",
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + " !important",
      borderWidth: "1px !important",
    },
    "&:after": {
      borderColor: primaryColor[0],
    },
  },
};
const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "text.primary",
  m: 1,
  border: 2,
  style: { padding: 20 },
};

class EquipmnetDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CustomerList: [],
      FilterdCustomer: [],
      SelectedIB: null,
      ContractHistory: null,
      loading: true,
      NewContractType: "",
      NewStartDate: "",
      NewEndDate: "",
      NewContractAmount: "",
    };
  }
  componentDidMount() {
    // reading seting for counting and actual values value from realtime database
    this.props.firebase.setting().on("value", (snapshot) => {
      const setting = snapshot.val();
      this.setState({ setting });
    });

    //getting initial machine list from collection

    this.props.firebase.getMindrayIB().onSnapshot((snapshot) => {
      let CustomerList = [];

      snapshot.forEach((doc) => {
        CustomerList.push({
          ...doc.data(),
          ID: doc.id,
        });
      });
      CustomerList.map((item) => {
        if (item.ID === this.props.match.params.id) {
          this.setState({
            SelectedIB: item,
          });
          if (item.ContractHistory)
            this.setState({
              ContractHistory: JSON.parse(item.ContractHistory),
            });
        }
      });
      this.setState({
        CustomerList,
        FilterdCustomer: CustomerList,
        loading: false,
      });
    });
  }
  componentWillUnmount() {
    this.setState({ ContractHistory: null });
  }
  deleteIB = () => {
    this.props.firebase
      .getSingleMindrayIB(this.props.match.params.id)
      .delete()
      .then(() => this.props.history.push("/admin/mindray"));
  };
  addNewCall = () => {
    const { Date, Type, Details, contractType, SelectedIB } = this.state;
    if ((Type.length, Details.length, contractType.length)) {
      let C_R = [];
      if (SelectedIB.C_R !== null && SelectedIB.C_R !== "")
        C_R = SelectedIB.C_R;

      C_R.push({
        Date,
        Type,
        Details,
        contractType,
      });
      this.props.firebase.getSingleIB(this.state.ID).update({
        C_R,
      });
    } else {
      alert("Fill All Fields");
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  toggleModale = () => {
    this.setState({ modalOpen: false });
    // alert("Updated Successfully");
  };
  handleToggle = () => {
    this.setState({ modalOpen: false });
    alert("Updated Successfully");
  };
  VerifyModificationPass = () => {
    const { setting, ModifiactionPassword, FinalModal } = this.state;
    if (ModifiactionPassword === setting.ModificationPass) {
      this.setState({
        modalConfirmOpen: false,
        [FinalModal]: true,
        ModifiactionPassword: "",
      });
    } else {
      alert("Wrong Password");
    }
  };
  updateContract = () => {
    const {
      SelectedIB,
      NewContractType,
      NewStartDate,
      NewEndDate,
      NewContractAmount,
      NewInvoiceNumber,
    } = this.state;
    if (
      NewContractType.length &&
      NewStartDate.length &&
      NewEndDate.length &&
      NewContractAmount.length &&
      NewInvoiceNumber.length
    ) {
      let ContractHistory = [];
      if (SelectedIB.ContractHistory)
        ContractHistory = JSON.parse(SelectedIB.ContractHistory);
      ContractHistory.push({
        StartDate: SelectedIB["StartDate"],
        ContractType: SelectedIB["Contract Type"],
        EndDate: SelectedIB["EndDate"],
        ContractAmount: SelectedIB["Contract Amount"],
        InvoiceNumber: SelectedIB["Invoice number"],
      });
      this.props.firebase
        .getSingleMindrayIB(SelectedIB.ID)
        .update({
          "Contract Type": NewContractType,
          StartDate: NewStartDate,
          EndDate: NewEndDate,
          "Invoice number": NewInvoiceNumber,
          "Contract Amount": NewContractAmount,
          ContractHistory: JSON.stringify(ContractHistory),
        })
        .then(() => alert("Updated Contract"));
      this.setState({
        NewContractType: "",
        NewStartDate: "",
        NewEndDate: "",
        NewContractAmount: "",
        modalUpdateContract: false,
      });
    } else {
      alert("Please fill all the details");
    }
  };
  render() {
    const { classes } = this.props;
    const { SelectedIB } = this.state;
    return (
      <div>
        <Modal
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          className={classes.paper}
        >
          <AddNewEquipment
            SelectedIB={this.state.SelectedIB}
            handleToggle={this.handleToggle}
          />
        </Modal>
        <Modal
          open={this.state.modalUpdateContract}
          onClose={() => this.setState({ modalUpdateContract: false })}
          className={classes.paper}
        >
          <Card>
            <CardHeader color="info">Provide new Contract Details</CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={4} sm={4} md={2}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel>Contract Type</InputLabel>
                    <Select
                      name="NewContractType"
                      value={this.state.NewContractType}
                      input={
                        <Input
                          classes={{ underline: classes.underline }}
                          name="NewContractType"
                          onChange={this.onChange}
                        />
                      }
                    >
                      <MenuItem value="AMC">AMC</MenuItem>
                      <MenuItem value="CMC">CMC</MenuItem>
                      <MenuItem value="Warranty">Warranty</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <CustomInput
                    labelText="New StartDate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "NewStartDate",
                      value: this.state["NewStartDate"],
                      type: "date",
                      onChange: this.onChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="New EndDate"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "NewEndDate",
                      type: "date",
                      value: this.state["NewEndDate"],
                      onChange: this.onChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Contract Amount"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "NewContractAmount",
                      type: "text",
                      value: this.state["NewContractAmount"],
                      onChange: this.onChange,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3}>
                  <CustomInput
                    labelText="Invoice No."
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      name: "NewInvoiceNumber",
                      type: "text",
                      value: this.state["NewInvoiceNumber"],
                      onChange: this.onChange,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button color="info" onClick={() => this.updateContract()}>
                Update
              </Button>
              <Button
                onClick={() => this.setState({ modalUpdateContract: false })}
              >
                cencel
              </Button>
            </CardBody>
          </Card>
        </Modal>
        <Modal
          open={this.state.modalConfirmOpen}
          onClose={() => this.setState({ modalConfirmOpen: false })}
          className={classes.paper}
        >
          <Card>
            <CardHeader color="danger">
              Please Confirm the Modification Password
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Modification Password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "ModifiactionPassword",
                  type: "password",
                  onChange: this.onChange,
                }}
              />
              <Button
                color="danger"
                onClick={() => this.VerifyModificationPass()}
              >
                Confirm
              </Button>
              <Button
                onClick={() => this.setState({ modalConfirmOpen: false })}
              >
                cencel
              </Button>
            </CardBody>
          </Card>
        </Modal>

        <Modal
          open={this.state.modalDeleteOpen}
          onClose={() => this.setState({ modalDeleteOpen: false })}
          className={classes.paper}
        >
          <Card>
            <CardHeader color="danger">
              Do you want to Delete This IB / Customer ?
            </CardHeader>
            <CardBody>
              <Button color="danger" onClick={this.deleteIB}>
                Yes Delete IT
              </Button>
              <Button onClick={() => this.setState({ modalDeleteOpen: false })}>
                cencel
              </Button>
            </CardBody>
          </Card>
        </Modal>

        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Customer Details</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          {!this.state.loading ? (
            <CardBody>
              <GridContainer>
                <GridItem xs={6} sm={6} md={6}>
                  <GridContainer
                    style={{
                      boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)",
                      borderRadius: 5,
                    }}
                    xs={12}
                    sm={12}
                    md={12}
                  >
                    <GridItem xs={6} sm={6} md={6}>
                      <p>Customer Name</p>

                      <p>Phone No.</p>
                      <p>Email ID</p>
                      <p>Pin code</p>
                      <p>District</p>
                      <p>Probe (01)</p>
                      <p>Probe (02)</p>
                      <p>Probe (03)</p>
                      <p>Probe (04)</p>
                      <p>Address</p>
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      <p>: {SelectedIB["Customer Name"]}</p>
                      <p>: {SelectedIB["Phone No"]}</p>
                      <p>: {SelectedIB["Email ID"]}</p>
                      <p>: {SelectedIB["Pin code"]}</p>
                      <p>: {SelectedIB["District"]}</p>
                      <p>: {SelectedIB["PROBE_01"]}</p>
                      <p>: {SelectedIB["PROBE_02"]}</p>
                      <p>: {SelectedIB["PROBE_03"]}</p>
                      <p>: {SelectedIB["PROBE_04"]}</p>
                      <p>: {SelectedIB["PROBE_04"]}</p>
                      <p>: {SelectedIB["Address"]}</p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <GridContainer
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.2)",
                      borderRadius: 5,
                    }}
                  >
                    <GridItem xs={6} sm={6} md={6}>
                      <p>Invoice Number</p>
                      <p> Serial No.</p>
                      <p> CRM No.</p>
                      <p>Start Date</p>
                      <p>End Date</p>
                      <p>Modal</p>
                      <p>Contract Type</p>
                      <p>Contract Amount</p>
                      <p>Installation Date</p>
                    </GridItem>
                    {SelectedIB !== null ? (
                      <GridItem xs={6} sm={6} md={6}>
                        <p>: {SelectedIB["Invoice number"]}</p>
                        <p>: {SelectedIB["Machine_Ser_NO"]}</p>
                        <p>: {SelectedIB["CRM_NO"]}</p>
                        <p>: {SelectedIB["StartDate"]}</p>
                        <p>: {SelectedIB["EndDate"]}</p>
                        <p>: {SelectedIB["Model"]}</p>
                        <p>: {SelectedIB["Contract Type"]}</p>
                        <p>: {SelectedIB["Contract Amount"]}</p>
                        <p>: {SelectedIB["installation date"]}</p>
                      </GridItem>
                    ) : (
                      <p>Loading</p>
                    )}
                  </GridContainer>
                  <Button
                    color="info"
                    onClick={() =>
                      this.setState({
                        modalUpdateContract: true,
                      })
                    }
                  >
                    New Contract
                  </Button>{" "}
                  <Button
                    color="primary"
                    onClick={() =>
                      this.setState({
                        modalConfirmOpen: true,
                        FinalModal: "modalOpen",
                      })
                    }
                  >
                    Edit Details
                  </Button>{" "}
                  <Button
                    color="danger"
                    onClick={() =>
                      this.setState({
                        modalConfirmOpen: true,
                        FinalModal: "modalDeleteOpen",
                      })
                    }
                  >
                    Delete IB
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <Modal
                  open={this.state.NewCallModal}
                  onClose={() => this.setState({ NewCallModal: false })}
                >
                  <Card>
                    <CardHeader color="primary">
                      <h4 className={classes.cardTitleWhite}>Add New Call</h4>
                      <p className={classes.cardCategoryWhite}></p>
                    </CardHeader>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Date"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "Date",
                              type: "date",
                              onChange: this.onChange,
                            }}
                          />
                        </GridItem>

                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Call Type"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "Type",
                              type: "text",
                              onChange: this.onChange,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Details"
                            id="email-address"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "Details",
                              type: "text",
                              onChange: this.onChange,
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={3}>
                          <CustomInput
                            labelText="Current Contract Type"
                            id="email-address"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              name: "contractType",
                              type: "text",
                              onChange: this.onChange,
                            }}
                          />
                          <Button onClick={this.addNewCall}>Sumit</Button>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </Modal>
                <GridItem xs={6} sm={6} md={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Call Type</TableCell>
                        <TableCell>Call Details</TableCell>
                      </TableRow>
                    </TableHead>
                    {SelectedIB.C_R
                      ? SelectedIB.C_R.map((Item) => (
                          <TableBody>
                            <TableCell>{Item.Date}</TableCell>
                            <TableCell>{Item.Type}</TableCell>
                            <TableCell>{Item.ContractType}</TableCell>
                            <TableCell>{Item.Details}</TableCell>
                          </TableBody>
                        ))
                      : null}
                  </Table>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Contract Type</TableCell>
                        <TableCell>Start Date</TableCell>
                        <TableCell>End Date</TableCell>
                        <TableCell>Contract Price</TableCell>
                      </TableRow>
                    </TableHead>
                    {this.state.ContractHistory !== null &&
                    this.state.ContractHistory !== ""
                      ? this.state.ContractHistory.map((Item) => (
                          <TableBody>
                            <TableCell>{Item.ContractType}</TableCell>
                            <TableCell>{Item.StartDate}</TableCell>
                            <TableCell>{Item.EndDate}</TableCell>

                            <TableCell>{Item.ContractAmount}</TableCell>
                          </TableBody>
                        ))
                      : null}
                  </Table>
                </GridItem>
              </GridContainer>
            </CardBody>
          ) : (
            <CircularProgress className={classes.progress} />
          )}
        </Card>
      </div>
    );
  }
}
export default compose(
  withFirebase,
  withStyles(styles)
)(EquipmnetDetails);
