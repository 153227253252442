import React, { Component } from "react";
import { withFirebase } from "layouts/Firebase";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { compose } from "recompose";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow
} from "@material-ui/core";
import {
  primaryColor,
  grayColor
} from "assets/jss/material-dashboard-react.jsx";
const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset"
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + " !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor[0]
    }
  }
};

export class AddModalName extends Component {
  constructor(props) {
    super(props);
    this.state = { Name: "", Manufacturer: "", Parts: [], PartNames: [] };
  }

  componentDidMount() {
    this.props.firebase.getPartNames().onSnapshot(snapshot => {
      let PartNames = [];
      snapshot.forEach(element => {
        PartNames.push({ ...element.data() });
      });
      this.setState({ PartNames });
    });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submit_handler = () => {
    const { Name, Manufacturer } = this.state;
    if (Name.length && Manufacturer.length) {
      this.props.firebase
        .getSinglePartName(Name)
        .set({
          Name,
          Manufacturer
        })
        .then(() => this.setState({ Name: "", Manufacturer: "" }))
        .then(() => alert("Part Added Successfully"))
        .catch(er => alert(JSON.stringify(er)));
    } else {
      alert("Please fill all the details.");
    }
  };

  render() {
    const { classes } = this.props;
    const { Name, Manufacturer, Parts } = this.state;
    return (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Add New Part</h4>
          <p className={classes.cardCategoryWhite}>Fill All Details</p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={3} sm={3} md={3}>
              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Name",
                  type: "Text",
                  value: this.state.Name,
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={3} sm={3} md={3}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Select Manufacturer</InputLabel>
                <Select
                  name="Manufacturer"
                  value={this.state.Manufacturer}
                  input={
                    <Input
                      classes={{ underline: classes.underline }}
                      name="Manufacturer"
                      onChange={this.onChange}
                    />
                  }
                >
                  <MenuItem value="Mindray">Mindray</MenuItem>
                  <MenuItem value="Fuji Films">Fuji Films</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>

          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={3}>
              <Button onClick={this.submit_handler} color="info">
                Submit
              </Button>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Manufacturer</TableCell>
                  <TableCell>No. of Parts</TableCell>
                </TableRow>
              </TableHead>
              {this.state.PartNames !== null
                ? this.state.PartNames.map(row => (
                    <TableBody>
                      <TableRow>
                        <TableCell>{row.Name}</TableCell>
                        <TableCell>{row.Manufacturer}</TableCell>
                        <TableCell>{row.Manufacturer}</TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                : null}
            </Table>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(style)
)(AddModalName);
