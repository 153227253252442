import React from "react";
import ReactDOM from "react-dom";

// core components
import App from "layouts/App/App";

import Firebase, { FirebaseContext } from "./layouts/Firebase";

import "assets/css/material-dashboard-react.css?v=1.7.0";

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById("root")
);
