import React, { Component } from "react";
import { withFirebase } from "layouts/Firebase";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { compose } from "recompose";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { Link } from "react-router-dom";

import {
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-react.jsx";
import PartList from "./Component/PartList";
import Tooltip from "@material-ui/core/Tooltip";

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative",
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  formControl: {
    paddingBottom: "10px",
    margin: "27px 0 0 0",
    position: "relative",
    verticalAlign: "unset",
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + " !important",
      borderWidth: "1px !important",
    },
    "&:after": {
      borderColor: primaryColor[0],
    },
  },
};

export class AddModalName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: "",
      Manufacturer: "",
      Parts: [],
      PartName: "",
      Type: "",
      SerNo: "",
      Outdate: "",
      Status: "",
      ModalNames: [],
    };
  }
  componentDidMount() {
    //Reading modalnames from the collection
    this.props.firebase.getModalNames().onSnapshot((snapshot) => {
      let ModalNames = [];

      snapshot.forEach((doc) => {
        ModalNames.push({
          ...doc.data(),
        });
      });
      this.setState({ ModalNames });
    });

    //Reading Part Names from the collection
    this.props.firebase.getPartNames().onSnapshot((snapshot) => {
      let PartNames = [];

      snapshot.forEach((doc) => {
        PartNames.push({
          ...doc.data(),
        });
      });
      this.setState({ PartNames });
    });
  }

  //submit handler for the modal form
  AddModal = () => {
    const { Name, Manufacturer } = this.state;
    if (Name.length && Manufacturer.length) {
      this.props.firebase
        .getSingleModalName(Name)
        .set({ Name, Manufacturer })
        .then(() =>
          this.setState({
            Name: "",
            Manufacturer: "",
          })
        )
        .then(() => alert("Modal Added Successfully"))
        .catch((err) => alert(JSON.stringify(err)));
    } else {
      alert("Please fill all the details.");
    }
  };
  addnewline = () => {
    let Parts = [];
    const { PartName, Type, SerNo, Outdate, Status } = this.state;
    if (this.state.Parts !== null) {
      Parts = this.state.Parts;
    }
    if (
      (PartName.length,
      Type.length,
      SerNo.length,
      Outdate.length,
      Status.length)
    ) {
      Parts.push({ PartName, Type, SerNo, Outdate, Status });
      this.setState({
        Parts,
        PartName: "",
        Type: "",
        SerNo: "",
        Outdate: "",
        Status: "",
      });
    } else {
      alert("Please Fill All Fields");
    }
  };
  deleteLine = (key) => {
    let Parts = [];
    if (this.state.Parts !== null) {
      Parts = this.state.Parts;
    }
    let index = -1;
    Parts.find((item, i) => {
      if (item.PartName === key) {
        index = i;
        return;
      }
    });
    Parts.splice(index, 1);
    this.setState({ Parts });
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { classes } = this.props;
    const { Name, Manufacturer, Parts } = this.state;
    return (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Add New Model</h4>
          <p className={classes.cardCategoryWhite}>Fill All Details</p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={3} sm={3} md={3}>
              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  name: "Name",
                  type: "Text",
                  value: Name,
                  onChange: this.onChange,
                }}
              />
            </GridItem>
            <GridItem xs={3} sm={3} md={3}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Select Manufacturer</InputLabel>
                <Select
                  name="Manufacturer"
                  value={this.state.Manufacturer}
                  input={
                    <Input
                      classes={{ underline: classes.underline }}
                      name="Manufacturer"
                      onChange={this.onChange}
                    />
                  }
                >
                  <MenuItem value="Mindray">Mindray</MenuItem>
                  <MenuItem value="Fuji Films">Fuji Films</MenuItem>
                  <MenuItem value="Fuji Films">Others</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          {/*
          <GridContainer>
            <PartList Items={this.state.Parts} />
          </GridContainer>
          <GridContainer>
            <GridItem xs={10} sm={10} md={2}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Select Part</InputLabel>
                <Select
                  name="PartName"
                  value={this.state.PartName}
                  input={
                    <Input
                      classes={{ underline: classes.underline }}
                      name="PartName"
                      onChange={this.onChange}
                    />
                  }
                >
                  {this.state.PartNames &&
                    this.state.PartNames.map(item => (
                      <MenuItem value={item.Name}>{item.Name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={2} sm={2} md={1}>
              <FormControl className={classes.formControl} fullWidth>
                <Tooltip
                  id="tooltip-top"
                  title="Add New Part"
                  placement="top"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Link to="/admin/inventory/addpart">
                    <Button color="info">+</Button>
                  </Link>
                </Tooltip>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Select Type</InputLabel>
                <Select
                  name="Type"
                  value={this.state.Type}
                  input={
                    <Input
                      classes={{ underline: classes.underline }}
                      name="Type"
                      onChange={this.onChange}
                    />
                  }
                >
                  <MenuItem value="Internal">Internal</MenuItem>
                  <MenuItem value="External">External</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomInput
                labelText="SerNo"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "SerNo",
                  type: "text",
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomInput
                labelText="Outdate"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Outdate",
                  type: "text",
                  onChange: this.onChange
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>
              <FormControl className={classes.formControl} fullWidth>
                <InputLabel>Select Status</InputLabel>
                <Select
                  name="Status"
                  value={this.state.Status}
                  input={
                    <Input
                      classes={{ underline: classes.underline }}
                      name="Status"
                      onChange={this.onChange}
                    />
                  }
                >
                  <MenuItem value="Available">Available</MenuItem>
                  <MenuItem value="Not Available">Not Available</MenuItem>
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer justify="flex-start">
            <GridItem xs={12} sm={12} md={3}>
              <Button onClick={this.addnewline} color="success">
                ADD Item
              </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}></GridItem>
                </GridContainer>*/}
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <Button onClick={this.AddModal} color="primary">
                Submit
              </Button>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Manufacturer</TableCell>
                </TableRow>
              </TableHead>
              {this.state.ModalNames !== null
                ? this.state.ModalNames.map((row) => (
                    <TableBody>
                      <TableRow>
                        <TableCell>{row.Name}</TableCell>
                        <TableCell>{row.Manufacturer}</TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                : null}
            </Table>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(style)
)(AddModalName);
