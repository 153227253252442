import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.jsx";

const ItemList = (props) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="Left">S/N</TableCell>
          <TableCell align="Left">ModalName</TableCell>
          <TableCell align="Left">SerialNo</TableCell>
          <TableCell align="Left">Quantity</TableCell>
          <TableCell align="Left">Status</TableCell>
        </TableRow>
      </TableHead>
      {props.Items !== null
        ? props.Items.map((row) => (
            <TableBody>
              <TableRow key={row.CustomerName}>
                <TableCell align="Left">{row.ID}</TableCell>
                <TableCell align="Left">{row.ModalName}</TableCell>
                <TableCell align="Left">{row.SerialNo}</TableCell>
                <TableCell align="Left">{row.SellQuantity}</TableCell>
                <TableCell align="Left">{row.StockStatus}</TableCell>
                {props.edit ? (
                  <TableCell align="Left">
                    <Button
                      onClick={() => props.deleteLine(row.ID)}
                      color="danger"
                    >
                      <Icon>delete</Icon>Delete
                    </Button>
                  </TableCell>
                ) : null}
              </TableRow>
            </TableBody>
          ))
        : null}
    </Table>
  );
};

export default ItemList;
