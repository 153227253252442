import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */
    app
      .firestore()
      .enablePersistence()
      .catch(function(err) {
        if (err.code == "failed-precondition") {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
        } else if (err.code == "unimplemented") {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
        }
      });

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    this.firestore = app.firestore();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .once("value")
          .then((snapshot) => {
            const dbUser = snapshot.val();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** User API ***
  setting = () => this.db.ref("setting");
  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref("users");

  bill = (uid) => this.db.ref(`bill/${uid}`);

  getbill = () => this.db.ref("bill");

  getIB = () => this.firestore.collection(`Fuji-IB`);
  Calls = () => this.firestore.collection(`Calls`);
  SingleCall = (id) => this.firestore.doc(`Calls/${id}`);
  getSingleIB = (id) => this.firestore.doc(`Fuji-IB/${id}`);
  getMindrayIB = () => this.firestore.collection(`Mindray_IB`);
  getSingleMindrayIB = (id) => this.firestore.doc(`Mindray_IB/${id}`);

  // **** Inventory API****
  getInventoryItems = () => this.firestore.collection(`InventoryItem`);
  getSingleInventoryItem = (id) => this.firestore.doc(`InventoryItem/${id}`);
  getModalNames = () => this.firestore.collection(`ModalName`);
  getSingleModalName = (id) => this.firestore.doc(`ModalName/${id}`);
  getStoreLocations = () => this.firestore.collection(`StoreLocation`);
  getSingleStoreLocation = (id) => this.firestore.doc(`StoreLocation/${id}`);
  getPartNames = () => this.firestore.collection(`PartName`);
  getSinglePartName = (id) => this.firestore.doc(`PartName/${id}`);
  getTickets = () => this.firestore.collection(`Tickets`);
  getSingleTicket = (id) => this.firestore.doc(`Tickets/${id}`);

  // *** Message API ***

  message = (uid) => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref("messages");
}

export default Firebase;
