import React, { Component } from "react";
import AddNewItem from "./AddNewItem";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { compose } from "recompose";
import { withFirebase } from "layouts/Firebase";
import { withGlobalStore, SetStore } from "../../layouts/withGlobalStore";
import moment from "moment";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Modal from "@material-ui/core/Modal";

const columns = [
  {
    name: "ID",
    label: "S/N",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "ModalName",
    label: "Modal Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "Manufacturer",
    label: "Manufacturer",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "SerialNo",
    label: "Serial No.",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "BillingDate",
    label: "Shipping Date",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "StockStatus",
    label: "Stock Status",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value === "InStock")
          return (
            <td style={{ backgroundColor: "#23CE6B", color: "#fff" }}>
              {value}
            </td>
          );
        else
          return (
            <td style={{ backgroundColor: "#DB162F", color: "#fff" }}>
              {value}
            </td>
          );
      },
    },
  },
  {
    name: "Type",
    label: "Type",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "Status",
    label: "Status",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value === "Active")
          return (
            <td style={{ backgroundColor: "#23CE6B", color: "#fff" }}>
              {value}
            </td>
          );
        else
          return (
            <td style={{ backgroundColor: "#DB162F", color: "#fff" }}>
              {value}
            </td>
          );
      },
    },
  },

  {
    name: "ID",
    label: "Details",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Link to={`/admin/Inventory/${value}`}>
            <Button color="info">Details</Button>
          </Link>
        );
      },
    },
  },
];
const options = {
  filterType: "checkbox",
  download: false,
  rowsPerPage: 100,
  responsive: "none",
  selectableRows: "none",
  print: true,
  downloadOptions: { filename: "Inventory.csv", separator: "," },
};

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  paper: {
    maxWidth: "70%",
    overflowY: "auto",
    margin: "auto",
  },
};
class ListItems extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CustomerList: [],
      ExpiredWarrantyList: [],
      FilterdCustomer: [],
      setting: {},
    };
  }
  componentDidMount() {
    // reading seting for counting and actual values value from realtime database
    this.props.firebase.setting().on("value", (snapshot) => {
      const setting = snapshot.val();
      this.setState({ setting });
    });

    //Reading Inventory Items from the collection
    this.props.firebase.getInventoryItems().onSnapshot((snapshot) => {
      let ItemList = [];
      snapshot.forEach((doc) => {
        ItemList.push({
          ...doc.data(),
          Sr: doc.ID,
        });
      });
      this.setState({ ItemList });
      this.Sort_Six_Months_Old();
    });

    this.setvalue();
  }
  setvalue = () => {
    return () => <SetStore key="rj" value="rj" />;
  };

  Sort_Six_Months_Old = () => {
    let ItemList = [];
    let ExpiredWarrantyList = [];
    ItemList = this.state.ItemList;
    const today = moment().format("YYYY-MM-DD");
    const t2 = moment([today]);

    ItemList.map((item) => {
      const shipingDate = moment(item.BillingDate).format("YYYY-MM-DD");
      const t1 = moment([shipingDate]);
      const monthDifference = t2.diff(t1, "months", true);
      if (isNaN(monthDifference) || monthDifference === "") {
        const date = 0;
      } else {
        if (monthDifference > 6 && item.StockStatus === "InStock") {
          ExpiredWarrantyList.push(item);
        }
      }
    });
    this.setState({ ExpiredWarrantyList, ExNotification: true });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Snackbar
          place={"tc"}
          color="danger"
          //icon={AddAlert}
          message={
            <>
              {this.state.ExpiredWarrantyList.length} Items are out Of 6 month
              stock warranty{" "}
              <Button
                color="info"
                onClick={() =>
                  this.setState({ openModal: true, ExNotification: false })
                }
              >
                Show Details
              </Button>
            </>
          }
          open={this.state.ExNotification}
          closeNotification={() => this.setState({ ExNotification: false })}
          close
        />
        <Modal
          open={this.state.openModal}
          onClose={() => this.setState({ openModal: false })}
          className={classes.paper}
        >
          <MUIDataTable
            data={this.state.ExpiredWarrantyList}
            columns={columns}
            options={options}
          />
        </Modal>
        <AddNewItem />
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Inventory Item List</h4>
            <p className={classes.cardCategoryWhite}></p>
          </CardHeader>
          <CardBody>
            {() => <SetStore key="rj" value="rj" />}
            <MUIDataTable
              data={this.state.ItemList}
              columns={columns}
              options={options}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default compose(
  withFirebase,
  withGlobalStore,
  withStyles(styles)
)(ListItems);
