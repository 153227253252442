import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Icon from "@material-ui/core/Icon";
import Button from "components/CustomButtons/Button.jsx";

const ItemTable = props => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="Left">Part Name</TableCell>
          <TableCell align="Left">Type</TableCell>
          <TableCell align="Left">SerNo</TableCell>
          <TableCell align="Left">Outdate</TableCell>
          <TableCell align="Left">Status</TableCell>
        </TableRow>
      </TableHead>
      {props.Items !== null
        ? props.Items.map(row => (
            <TableBody>
              <TableRow key={row.CustomerName}>
                <TableCell align="Left">{row.PartName}</TableCell>
                <TableCell align="Left">{row.Type}</TableCell>
                <TableCell align="Left">{row.SerNo}</TableCell>
                <TableCell align="Left">{row.Outdate}</TableCell>
                <TableCell align="Left">{row.Status}</TableCell>
                {props.edit ? (
                  <TableCell align="Left">
                    <Button
                      onClick={() => props.deleteLine(row.ItemName)}
                      color="danger"
                    >
                      <Icon>delete</Icon>Delete
                    </Button>
                  </TableCell>
                ) : null}
              </TableRow>
            </TableBody>
          ))
        : null}
    </Table>
  );
};

export default ItemTable;
