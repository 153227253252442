import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 5,
  },
  reportTitle: {
    fontSize: 6,
    textAlign: "center",
    textTransform: "uppercase",
  },
  headerContainerTop: {
    marginTop: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  headerContainer: {
    maxWidth: "50%",
    textOverflow: "wrap",
  },
  label: {
    fontSize: 8,
    fontWeight: "black",
  },
  invoiceDateContainer: {
    flexDirection: "row",
  },
});

const InvoiceThankYouMsg = () => (
  <Fragment>
    <View style={styles.headerContainerTop}>
      <View style={styles.headerContainer}>
        <Text style={{ fontWeight: "bold" }}>Remarks:</Text>

        <Text style={{ fontSize: 9 }}>Declaration</Text>
        <Text style={{ fontSize: 7 }}>
          We declare that this invoice shows the actual price of the goods
          described and that all particulars are currect and true.{" "}
        </Text>
      </View>
      <View style={{}}>
        <View style={styles.invoiceNoContainer}>
          <Text style={styles.label}>Company's Bank Details</Text>
          <Text style={styles.invoiceDate}></Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Bank Name: </Text>
          <Text style={{ fontSize: 7 }}> HDFC BANK LIMITED 50200029839929</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Account No: </Text>
          <Text style={{ fontSize: 7 }}> 50200029839929</Text>
        </View>
        <View style={styles.invoiceDateContainer}>
          <Text style={styles.label}>Branch & IFSC code: </Text>
          <Text style={{ fontSize: 7 }}> RAJENDRA NAGAR & HDFC0002851</Text>
        </View>
      </View>
    </View>
    <View style={styles.headerContainerTop}>
      <View>
        <Text style={{ fontSize: 9 }}>Customer's Seal and Signature</Text>
      </View>
      <View>
        <Text style={{ fontSize: 9 }}>For MED SOLUTIONS </Text>
        <Text style={{ fontSize: 7, marginTop: 27 }}>
          Authorised Signatory{" "}
        </Text>
      </View>
    </View>
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>SUBJECT TO PATNA JURISDICTION</Text>
      <Text style={styles.reportTitle}>
        This is a Computer Generated Invoice
      </Text>
    </View>
  </Fragment>
);

export default InvoiceThankYouMsg;
