import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 15,
    maxWidth: "50%",
    textOverflow: "wrap",
    fontSize: 10,
  },
  billTo: {
    marginTop: 5,
    paddingBottom: 3,
    fontFamily: "Helvetica-Oblique",
  },
});

const BillTo = ({ invoice }) => (
  <View style={styles.headerContainer}>
    <Text style={styles.billTo}>Bill To:</Text>
    <Text style={{ fontSize: 9 }}>{invoice.company}</Text>
    <Text style={{ fontSize: 9 }}>{invoice.address}</Text>
    <Text style={{ fontSize: 9 }}>{invoice.phone}</Text>
    <Text style={{ fontSize: 9 }}>{invoice.email}</Text>
  </View>
);

export default BillTo;
