/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { compose } from "recompose";
import { withFirebase } from "layouts/Firebase";
import { withRouter } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { Link } from "react-router-dom";

import { Modal } from "@material-ui/core";
import AddNewEquipment from "./AddNewEquipment";
import MUIDataTable from "mui-datatables";
import moment from "moment";
const columns = [
  {
    name: "Ser_No",
    label: "Serial No.",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Customer Name",
    label: "Customer Name",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "City",
    label: "City",
    options: {
      filter: true,
      sort: false,
    },
  },
  {
    name: "Contract Type",
    label: "Contract Type",
    options: {
      filter: true,
      sort: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        if (value === "AMC" || value === "CMC" || value === "cmc")
          return (
            <td style={{ backgroundColor: "#23CE6B", color: "#fff" }}>
              {value}
            </td>
          );
        else
          return (
            <td style={{ backgroundColor: "#DB162F", color: "#fff" }}>
              {value}
            </td>
          );
      },
    },
  },
  {
    name: "Customer/ Clinic Name",
    label: "Customer/ Clinic Name",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "Equipment",
    label: "Equipment",
    options: {
      filter: true,
      sort: false,
    },
  },

  {
    name: "Model",
    label: "Model",
    options: {
      filter: true,
      sort: false,
      display: false,
    },
  },
  {
    name: "District",
    label: "District",
    options: {
      filter: true,
      sort: false,
      display: false,
    },
  },
  {
    name: "ID",
    label: "Details",
    options: {
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Link to={`/admin/table/${value}`}>
            <Button color="info">Details</Button>
          </Link>
        );
      },
    },
  },
];

const options = {
  filterType: "checkbox",
  download: false,
  rowsPerPage: 100,
  selectableRows: "none",
  download: false,
  print: false,
};

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  paper: {
    maxWidth: "70%",
    overflowY: "auto",
    margin: "auto",
  },
};
class TableList extends Component {
  constructor(props) {
    super(props);

    this.state = { CustomerList: [], FilterdCustomer: [] };
  }
  componentDidMount() {
    this.props.firebase.getIB().onSnapshot((snapshot) => {
      let CustomerList = [];

      snapshot.forEach((doc) => {
        CustomerList.push({
          ...doc.data(),
          ID: doc.id,
        });
      });
      this.setState({
        CustomerList,
        FilterdCustomer: CustomerList,
        loading: false,
      });
    });
  }

  handleSearch = (event) => {
    // Variable to hold the original version of the list
    let currentList = [];
    // Variable to hold the filtered list before putting into state
    let newList = [];

    // If the search bar isn't empty
    if (event.target.value !== "") {
      // Assign the original list to currentList
      currentList = this.state.CustomerList;

      // Use .filter() to determine which items should be displayed
      // based on the search terms
      newList = currentList.filter((item) => {
        // change current item to lowercase
        if (item.hasOwnProperty("Customer Name")) {
          const lc = item["Customer Name"].toLowerCase();
          // change search term to lowercase
          const filter = event.target.value.toLowerCase();
          // check to see if the current list item includes the search term
          // If it does, it will be added to newList. Using lowercase eliminates
          // issues with capitalization in search terms and search content
          return lc.includes(filter);
        }
      });
    } else {
      // If the search bar is empty, set newList to original task list
      newList = this.state.CustomerList;
    }
    // Set the filtered state based on what our rules added to newList
    this.setState({
      FilterdCustomer: newList,
    });
  };
  handleUpdate = () => {
    // Variable to hold the original version of the list
    let currentList = [];

    currentList = this.state.CustomerList;
    currentList.map((item) => {
      const EndDate = moment(item.EndDate).format("YYYY-MM-DD");
      const StartDate = moment(item.EndDate).format("YYYY-MM-DD");
      const installDate = moment(item["installation date"]).format(
        "YYYY-MM-DD"
      );
      this.props.firebase.getSingleIB(item.ID).update({
        EndDate,
        StartDate,
        "installation date": installDate,
      });
      console.log(`done + ${item.ID} +E${EndDate}`);
    });
  };
  render() {
    const { classes } = this.props;
    const { FilterdCustomer } = this.state;
    return (
      <GridContainer>
        <Modal
          open={this.state.modalOpen}
          onClose={() => this.setState({ modalOpen: false })}
          className={classes.paper}
        >
          <AddNewEquipment />
        </Modal>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Customer List</h4>
              <p className={classes.cardCategoryWhite}></p>
            </CardHeader>
            <CardBody>
              <Button
                color="success"
                onClick={() => this.setState({ modalOpen: true })}
              >
                Equipment List
              </Button>
              <MUIDataTable
                data={FilterdCustomer}
                columns={columns}
                options={options}
                onRowsSelect={(currentRowsSelected) => alert("hellow")}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

TableList.propTypes = {
  classes: PropTypes.object,
};

export default compose(
  withFirebase,
  withRouter,
  withStyles(styles)
)(TableList);
