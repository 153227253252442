/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { withFirebase } from "layouts/Firebase";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { compose } from "recompose";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import Quote from "components/Typography/Quote.jsx";
import Muted from "components/Typography/Muted.jsx";
import Primary from "components/Typography/Primary.jsx";
import Info from "components/Typography/Info.jsx";
import Success from "components/Typography/Success.jsx";
import Warning from "components/Typography/Warning.jsx";
import Danger from "components/Typography/Danger.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const INITIAL_STATE = {
  "Amount Paid": 0,
  " t r": 0,
  Rate: 0,
  Unit: 0,
  AmountDue: 0,
  Status: true
};
class AddNewEquipment extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this.props.SelectedIB };
  }

  submit_handler = () => {
    if (this.state.ID)
      this.props.firebase.getSingleIB(this.state.ID).update({
        ...this.state
      });
    else this.props.firebase.getIB().add({ C_R: [], ...this.state });
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Create a New Bill</h4>
          <p className={classes.cardCategoryWhite}>
            Created using Roboto Font Family
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Customer Code"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Customer Code",
                  type: "text",
                  value: this.state["Customer Code"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Customer Name"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Customer Name",
                  type: "text",
                  value: this.state["Customer Name"],
                  onChange: this.onChange
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Customer/ Clinic Name"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Customer/ Clinic Name",
                  type: "text",
                  value: this.state["Customer/ Clinic Name"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Phone No."
                id="last-name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Phone No",
                  type: "text",
                  value: this.state["Phone No"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Address"
                id="address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Address",
                  type: "text",
                  value: this.state["Address"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Email ID"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Email ID",
                  type: "email",
                  value: this.state["Email ID"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="District"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "District",
                  type: "text",
                  value: this.state["District"],
                  onChange: this.onChange
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="State"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "State",
                  type: "text",
                  value: this.state["State"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Pin code"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Pin code",
                  type: "text",
                  value: this.state["Pin code"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Equipment"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Equipment",
                  type: "text",
                  value: this.state["Equipment"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Model"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Model",
                  type: "text",
                  value: this.state["Model"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Ser_No"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Ser_No",
                  value: this.state["Ser_No"],
                  type: "text",
                  onChange: this.onChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="StartDate"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "StartDate",
                  value: this.state["StartDate"],
                  type: "date",
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="EndDate"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "EndDate",
                  type: "date",
                  value: this.state["EndDate"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Installation Date"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "installation date",
                  type: "date",
                  value: this.state["installation date"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="flex-end">
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Invoice number"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Invoice number",
                  type: "text",
                  value: this.state["Invoice number"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Contract Type"
                id="Amount Paid"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Contract Type",
                  type: "text",
                  value: this.state["Contract Type"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Contract Price"
                id="Amount Paid"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Contract Price",
                  type: "text",
                  value: this.state["Contract Price"],
                  onChange: this.onChange
                }}
              />
            </GridItem>
          </GridContainer>

          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={3}>
              <Button onClick={this.submit_handler} color="info">
                Submit
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(style)
)(AddNewEquipment);
