/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { withFirebase } from "layouts/Firebase";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import { compose } from "recompose";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";
// core components

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

const style = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const INITIAL_STATE = {
  AmountPaid: 0,
  Total: 0,
  Rate: 0,
  Unit: 0,
  AmountDue: 0,
  Status: true
};
class TypographyPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  submit_handler = () => {
    const {
      CustomerName,
      Email,
      Phone,
      AmountPaid,
      Total,
      Rate,
      Unit,
      Address,
      AmountDue
    } = this.state;
    this.props.firebase.bill(CustomerName).update({
      CustomerName,
      Email,
      Phone,
      AmountPaid,
      Total,
      Rate,
      Unit,
      Address,
      AmountDue
    });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { classes } = this.props;
    let { AmountPaid, Total, Rate, Unit, AmountDue, Status } = this.state;
    Total = Number(Rate) * Number(Unit);
    AmountDue = Number(Total) - Number(AmountPaid);

    return (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>Create a New Bill</h4>
          <p className={classes.cardCategoryWhite}>
            Created using Roboto Font Family
          </p>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText="Customer Name"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "CustomerName",
                  type: "text",
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Phone No."
                id="last-name"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Phone",
                  type: "text",
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Date"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Date",
                  type: "date",
                  onChange: this.onChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={5}>
              <CustomInput
                labelText="Address"
                id="address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  multiline: true,
                  rows: 3,
                  name: "Address",
                  type: "text",
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Email address"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Email",
                  type: "email",
                  onChange: this.onChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Item Name"
                id="username"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "ItemName",
                  type: "text",
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Rate"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Rate",
                  type: "text",
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Unit"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "Unit",
                  type: "text",
                  onChange: this.onChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Total"
                id="email-address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  name: "Total",
                  type: "text",
                  value: Total,
                  onChange: this.onChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="flex-end">
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Amount Paid"
                id="Amount Paid"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  name: "AmountPaid",
                  type: "text",
                  onChange: this.onChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="flex-end">
            <GridItem xs={12} sm={12} md={3}>
              <CustomInput
                labelText="Amount Due"
                id="Amount Due"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: true,
                  name: "AmountDue",
                  value: AmountDue,
                  type: "text",
                  onChange: this.onChange
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer justify="flex-end">
            <InputLabel>{Number(AmountDue) === 0 ? "PAID" : "DUE"}</InputLabel>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={3}>
              <Button onClick={this.submit_handler} color="info">
                Submit
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

export default compose(
  withFirebase,
  withStyles(style)
)(TypographyPage);
